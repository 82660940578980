import {HostListener, Component, OnInit, Input} from '@angular/core';
import { HttpService } from '../../services/http.service';
import ymaps from 'ymaps';
import {AppComponent as App} from '../../app.component';
import {PanelApertures} from '../../pages/p404/data/panel-apertures';
import {BricksApertures} from '../../pages/p404/data/bricks-apertures';
import {StalinApertures} from '../../pages/p404/data/stalin-apertures';
import {NewApertures} from '../../pages/p404/data/new-apertures';
import {Pik1Apertures} from '../../pages/p404/data/pik1-apertures';
import {Pik2Apertures} from '../../pages/p404/data/pik2-apertures';
import {Pik3Apertures} from '../../pages/p404/data/pik3-apertures';

@Component({
  selector: 'app-dadata-form',
  templateUrl: './dadata-form.component.html',
  styleUrls: ['./dadata-form.component.scss']
})

export class DadataFormComponent implements OnInit {

  // Модель для связи input'а для ввода адреса
  public addressModel: any;
  // Массив с выборкой адресов с API suggestions.dadata
  public arAddress: any = {};
  // Array with choosen address
  public arChoosenAddress: any;
  // Url успешно найденного адресса
  public virtualUrl:string;
  public successFoundAddressUrl: string;
  public successFoundAddressUrlStreet: string;
  public successFoundAddressUrlHouse: string;

  /** Yandex Map API */
  public showModal = false;
  public myMap: ymaps;
  public innerWidth: any;
  public isMobile = false;
  public selectedAddress = 'Адрес не определен!';
  public showMobileAddressSearch = false;
  /** Yandex Map API */

  public blink = true;

  constructor(
    private http: HttpService,
    public app: App
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 768) {
      this.isMobile = true;
    }

    ymaps
      .load('https://api-maps.yandex.ru/2.1/?apikey=23317450-daa1-4318-94b3-7afd5e1a4bfd&lang=ru_RU')
      .then(maps => {

        this.myMap = new maps.Map('map', {
          center: [55.76, 37.64],
          zoom: 10,
          controls: []
        }, {
          suppressMapOpenBlock: true
        });

        this.myMap.events.add('click', (e) => {
          const userCoordinates = e.get('coords');
          this.addPointByCoords(userCoordinates);
          return;
        });
      });
  }

  /** Yandex Map API */
  /**
   *
   */

  public addPointByBrowser(): void {

    ymaps
      .load('https://api-maps.yandex.ru/2.1/?apikey=23317450-daa1-4318-94b3-7afd5e1a4bfd&lang=ru_RU')
      .then(maps => {

        maps.geolocation.get({
          provider: 'browser'
        }).then(
          result => {
            const userAddress = result.geoObjects.get(0).properties.get('text');
            const userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();

            this.selectedAddress = userAddress;
            this.addPointByCoords(userCoodinates);
          }
        ).catch(
          error => console.log('Error: ', error)
        );
      })
      .catch(error => console.log('Failed to load Yandex Maps', error));
    }

  /**
   * What is happening:
   *
   * - Send request (coords inside) to YaMap,
   *   get address as geoObjects of YaMap
   *
   * - Send address (string, property of geoObject)
   *   to Dadata & get Dadata object
   *
   * - Execute chooseAddress with Dadata object
   *   as argument
   *
   * - Insert baloon with sourse coords and
   *   detected address.value as hint content
   */
  public addPointByCoords(userCoodinates: any): void {
    ymaps
      .load('https://api-maps.yandex.ru/2.1/?apikey=23317450-daa1-4318-94b3-7afd5e1a4bfd&lang=ru_RU')
      .then(maps => {
        maps.geocode(userCoodinates).then(res => {
          const clickAddress = res.geoObjects.get(0).properties._data.text;
          this.http.getDataFromDadata(clickAddress).subscribe(data => {
            this.arAddress = data;
            if (this.arAddress.suggestions[0]) {
              this.chooseAddress(this.arAddress.suggestions[0]);
              return;
            }
            this.getHouseInfoByAddress({});
          });
          this.myMap.geoObjects.removeAll();
          // Making geoobject with geometry type "point"
          const geoObject = new maps.GeoObject({
              // Geometry description
              geometry: {type: 'Point', coordinates: userCoodinates},
              // Properties
              properties: {iconContent: '', balloonContent: clickAddress}
            },
            {
              // Options
              // Ballon icon will be responsive for your content
              preset: 'islands#redStretchyIcon',
              // Point will be draggable
              draggable: false
            }
          );

          // Text for desktop title in address modal window
          if (!this.isMobile) {
            this.addressModel = clickAddress;
          }

          // Text for mobile title over "Change address" button
          this.selectedAddress = clickAddress;

          // Add baloon & map center
          this.myMap.geoObjects.add(geoObject);
          this.myMap.setCenter(userCoodinates, 17, { duration: 500 });

        }).catch(err => {
          console.log(err);
        });
      }).catch(err => {
      console.log(err);
    });
  }

  /**
   *
   *
   */
  public addPointByAddress(address: string) {
    ymaps
      .load('https://api-maps.yandex.ru/2.1/?apikey=23317450-daa1-4318-94b3-7afd5e1a4bfd&lang=ru_RU')
      .then(maps => {
        const myGeocoder = maps.geocode(address);
        myGeocoder.then(res => {
          const userCoodinates = res.geoObjects.get(0).geometry.getCoordinates();
          // Создаем геообъект с типом геометрии "Точка".
          const geoObject = new maps.GeoObject(
            {
              // Описание геометрии.
              geometry: {
                type: 'Point',
                coordinates: userCoodinates
              },
              // Свойства.
              properties: {
                // Контент метки.
                iconContent: '',
                balloonContent: address
              }
            },
            {
              // Опции.
              // Иконка метки будет растягиваться под размер ее содержимого.
              preset: 'islands#redStretchyIcon',
              // Метку можно перемещать.
              draggable: false
            }
          );
          // Add baloon & centered map
          this.myMap.geoObjects.add(geoObject);
          this.myMap.setCenter(userCoodinates, 17, { duration: 500 });
        }).catch(
          err => console.log(err));
      }).catch(err => console.log(err));
  }
  /** Yandex Map API */


  public dataFromDadata() {
    this.blink = false;
    if (this.addressModel) {
      this.http.getDataFromDadata(this.addressModel).subscribe((data) => {
        this.arAddress = data;
      });
    }
  }

  public chooseAddress(address) {
    localStorage.setItem('app.address', JSON.stringify(address));

    this.addressModel = this.selectedAddress = address.value;
    this.arChoosenAddress = address;

    if (address.data.house) {
      this.closeSuggestions();
      this.getHouseInfoByAddress(address.value);
    }
  }

  private closeSuggestions() {
    this.arAddress = {};
  }

  private getHouseInfoByAddress(address) {
    let $this = this;

    this.http.getHouseInfoByAddress(address).subscribe((data) => {
      if (data) {
        if ((data as any).status === 1) {
          // For get params 'else' added 17/12/19
          if (this.arChoosenAddress.data.street != null) {
            this.successFoundAddressUrlStreet = this.arChoosenAddress.data.street + ' ' + this.arChoosenAddress.data.street_type_full;
          } else if (this.arChoosenAddress.data.settlement != null) {
            this.successFoundAddressUrlStreet = this.arChoosenAddress.data.settlement +
              ' ' + this.arChoosenAddress.data.settlement_type_full;
          } else if (this.arChoosenAddress.data.city_with_type != null) {
            this.successFoundAddressUrlStreet = this.arChoosenAddress.data.city_with_type;
          }

          this.successFoundAddressUrlHouse = this.arChoosenAddress.data.house;

          if (this.arChoosenAddress.data.block) {
            this.successFoundAddressUrlHouse += this.arChoosenAddress.data.block_type + this.arChoosenAddress.data.block;
          }
          let series = (data as any).series;
          $this.http.getVirtualData(series).subscribe((data) => {
            localStorage.setItem('virtualData', JSON.stringify(data));
            if (data!=null){
              this.virtualUrl = '/virtual/' + series.replace(/-/g, '');
              localStorage.setItem('virtualUrl', this.virtualUrl);
            }
            else{
             
              localStorage.setItem('virtualUrl', null);
            }
            
          });
          //console.log(data);
          if ((data as any).isVirtual === true){
            let series = (data as any).series;
            //console.log(series);

            this.successFoundAddressUrl = '/virtual/' + series.replace(/-/g, '');
            localStorage.setItem('backUrl', this.successFoundAddressUrl);

            /*$this.http.getVirtualData(series).subscribe((data) => {
              localStorage.setItem('virtualData', JSON.stringify(data));
            });*/

            return;
          }
          
          if (
              (data as any).series === 'pik-1' || (data as any).series === 'pik-2' || (data as any).series === 'pik-3' ||
              (data as any).series === 's-pb-1lg-507' || (data as any).series === 'sakb-2-1900'
            ) {
            this.successFoundAddressUrl = '/notfound/' + (data as any).series.replace(/-/g, '');
            localStorage.setItem('backUrl', this.successFoundAddressUrl);
            return;
          }

          this.successFoundAddressUrl = '/house/' + (data as any).type + '/' + (data as any).series + '/';

        } else {
          this.successFoundAddressUrl = '/notfound/common';
          $this.http.getVirtualData('panelnyy-dom').subscribe((data) => {
            localStorage.setItem('panelnyy-dom', JSON.stringify(data));
          });
          $this.http.getVirtualData('kirpichnyy-dom').subscribe((data) => {
            localStorage.setItem('kirpichnyy-dom', JSON.stringify(data));
          });
          $this.http.getVirtualData('stalinskiy-dom').subscribe((data) => {
            localStorage.setItem('stalinskiy-dom', JSON.stringify(data));
          });
          $this.http.getVirtualData('novostroyka').subscribe((data) => {
            localStorage.setItem('novostroyka', JSON.stringify(data));
          });
        }
      }
    });
  }
}
