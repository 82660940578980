import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-sill',
  templateUrl: './sill.component.html',
  styleUrls: ['./sill.component.scss']
})
export class SillComponent implements OnInit {

  @Input() modal;
  public appConfig;

  constructor(public calculator: Calculator) { }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public closeModal = () => this.calculator.closeModal();
  public closeModalWithOldConfig = () => this.calculator.closeModalWithOldConfig();
  public getCost = () => (this.calculator.getCost(this.modal.windowId, 'sill'));
  public changeCostValue = (valueId: any): void => this.calculator.changeCostValue('sill', valueId.toString());

}
