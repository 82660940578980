import { Component, ElementRef, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpService } from '../../services/http.service';
import { HttpClient, HttpResponse, HttpResponseBase, HttpHeaders } from '@angular/common/http';
import { AppComponent } from '../../app.component';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

interface Modal {
  windowId?: string;
  type?: string;
  values?: any;
  windowData?: any;
}

interface Order {
  fias?: string;
  series?: string;
  preOrderId?: string;
  discount?: number;
  discountType?: string;
  planId?: string;
  planImg?: string;
  discharge?: number;
  total?: number;
  totalNoDiscount?: number;
  delivery?: number;
  apertures?: any;
  install?: number;
  seriesId?: string;
  street?: string;
  house?: string;
  appartFloor?: string;
  lifting?: any;
  statusDiscount?: boolean;
}

interface Plan {
  apertures: {};
  delivery: any;
  delivery2: any;
  lifting: any;
  id: string;
  lifeTimeReset: any;
}

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private http: HttpService,
    private app: AppComponent,
    private router: Router,
    private httpClient: HttpClient,
    private el: ElementRef) { }


  public url: string;
  public arPlans: any;
  public activeCountRooms = 1;
  public street: string;
  public fullAddress: string;
  public house: string;
  public plan: Plan;
  public seriesId: string;
  public planId: string;
  public plan3D_index: string;
  public detailedImages: object;
  public modal: Modal = {};
  public showFacing: any = {};
  public appConfig: any = {
    apiUrl: 'https://lk.azbukaokna.ru'
  };
  private httpOptions: any = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token eefe28ba52dd44c247870680c7bcafb2faf676a6'
    })
  };
  public isVirtual: boolean;
  public virtualArr: any;
  public virtualUrl: string;
  public apartment_nofound: boolean;
  public lifeTimeReset: number;
  public order: Order = {};
  public fias: string;
  public orderIdFromUrl: string;
  public addSeriesForm = {
    errors: {},
    series: this.seriesId,
    name: null,
    email: null,
    phone: null,
    file: null
  };
  public preOrderForm = {
    errors: Error,
    show: false,
    sended: false,
    name: null,
    email: null,
    phone: null
  };
  public city: string;
  public building: string;

  public appartFloor = 1;

  public custom: boolean;
  public searchParams: URLSearchParams;
  public customPageTitle: string;

  public backUrl: string;
  public discountCheck: boolean;
  public discountType: string;
  public discountDescription: string;
  public orderDiscount: number;
  public statusDiscount: boolean;
  /*public discount: any = {
    type: null,
    count: null
  }*/

  ngOnInit() {

    this.searchParams = new URLSearchParams(window.location.search);
    this.custom = this.searchParams.get('custom') && this.searchParams.get('custom') === '1';
    this.url = this.router.url;
    this.app.loading = true;
    this.seriesId = String(this.route.snapshot.paramMap.get('seriesId'));
    this.lifeTimeReset = new Date().getTime();
    this.discountCheck = this.http.discount.check;
    this.orderDiscount = this.http.discount.orderDiscount;
    this.planId = String(this.route.snapshot.paramMap.get('planId'));
    this.apartment_nofound = false;
    if (localStorage.getItem('virtualData') != null) {
      this.virtualArr = JSON.parse(localStorage.getItem("virtualData"));
      if (this.virtualArr != null) {
        if (this.virtualArr.series && this.virtualArr.series == this.seriesId) {
          this.virtualUrl=localStorage.getItem("virtualUrl");
          this.apartment_nofound = true;
        }
      }
    }
    this.route.queryParamMap.pipe(map(params => params.get('FULL_ADDRESS')))
    .subscribe(res => this.fullAddress = res ? res : '');

    this.route.queryParamMap.pipe(map(params => params.get('street')))
      .subscribe(res => this.street = res ? res : '');

    this.route.queryParamMap.pipe(map(params => params.get('house')))
      .subscribe(res => this.house = res ? res : '');

    this.route.queryParamMap.pipe(map(params => params.get('activeCountRooms')))
      .subscribe(res => this.activeCountRooms = res ? Number(res) : this.activeCountRooms);

    this.backUrl = localStorage.getItem('backUrl');

    if (this.seriesId) {
      this.getPlans();
      // 3.2
      if (this.planId === 'null') {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          eCategory: 'calculator',
          eAction: 'openCalcAddressFound',
          eLabel: '',
          eNI: true,
          event: 'GAEvent'
        });
      }
    }

    if (this.planId !== 'null') {
      this.getPlan();
    }

    this.getFullAddress();
  }

  public getFullAddress = () => {
    const appAddress = JSON.parse(localStorage.getItem('app.address')).data;
    if (appAddress.block) {
      this.building = appAddress.block_type_full + ' ' + appAddress.block;
    }
    if (appAddress.city) {
      this.city = appAddress.city;
    }
    const region = appAddress.region === 'Москва' ? '' : appAddress.region_with_type + ' ';
    const building = appAddress.block ? appAddress.block_type_full + ' ' + appAddress.block : '';

    this.fullAddress = JSON.parse(localStorage.getItem('app.address')).value;
    this.fias = appAddress.fias_id;
  }
  public getOrderDiscount = () => {
    let order = this.makeOrder();
    let request = {
      'order': this.order,
      'siteId': 1
    }
    return this.httpClient.post('https://old.mosokna.ru/api20/mosokna/getDiscountAdditional/', request, this.httpOptions).toPromise().then((data: any) => {
      let response = JSON.parse(data);
      if (response.result) {
        this.http.discount.check = true;
        this.discountCheck = true;
        this.http.discount.description = response.desecription;
        this.discountDescription = response.desecription;
        if (response.discount.indexOf("%") !== -1) {
          this.discountType = 'per';
          this.http.discount.type = 'per';
          this.order.discountType = "%";
        } else {
          this.http.discount.type = 'price';
          this.discountType = 'price';
          this.order.discountType = "руб.";
        }
        this.http.discount.orderDiscount = parseInt(response.discount);
        this.order.discount = parseInt(response.discount)
        this.orderDiscount = parseInt(response.discount);
      }
    });
  }
  public checkLocalStorageLifeTime = () => {
    const checkTime = new Date().getTime() - 3600000;

    return this.lifeTimeReset > checkTime ? true : false;
  }

  public createDetailImages = (index) => {
    for (const element of this.arPlans.room_plans[index]) {
      this.http.updateDetailImage(element.code).subscribe(
        (data) => {
          if (data) {
            let plan3D = data.soap3dImage ? 'data:image/png;base64,' + data.soap3dImage : false;
            this.detailedImages[element.code] = plan3D;
          }
        }
      );
    }
  }

  private getPlans = () => {
    this.http.getPlans(this.seriesId).subscribe(
      (res) => {
        if (res) {
          this.arPlans = res;
          this.customPageTitle = this.arPlans.series_name;
          if (this.planId === 'null') {
            this.app.loading = false;
          }
          this.detailedImages = {};
          let index = this.activeCountRooms ? this.activeCountRooms : 1;
          this.createDetailImages(index);
        }
      }
    );
  }
  private getPlan = (): any => {
    const apertureList = [];
    let custom = '';
    if (this.custom) {
      let i = 0;
      this.searchParams.forEach((value, key) => {
        if (key !== 'custom') {
          apertureList[i] = key + '=' + value;
          i++;
        }
      });
      custom = '&custom=' + apertureList.toString();
    }

    const now = new Date();
    const checkTime = now.getTime() - 3600000;
    const checkIfLoadOldPlan = (
      this.app.getPlan() !== null &&
      this.planId === this.app.getPlan().id &&
      (typeof this.app.getPlan().lifeTimeReset !== 'undefined' && this.app.getPlan().lifeTimeReset > checkTime)
    );

    if (checkIfLoadOldPlan) {
      this.plan = this.app.getPlan();
      setTimeout(() => {
        const $winBlockExists = this.el.nativeElement.querySelector('.calculator .windows-block');
        if ($winBlockExists) {
          this.app.loading = false;
          this.app.scrollToElement('.calculator .windows-block');
        }
      }, 500);
    }

    if (!checkIfLoadOldPlan) {
      this.http.getPlan(this.planId, custom).subscribe(res => {
        if (res) {
          this.plan = res;
          this.plan.lifting = {
            active: false,
            value: 0
          };
          this.plan.lifeTimeReset = now.getTime();
          // tslint:disable-next-line:radix
          if (parseInt(this.getCost('0', 'lifting').values[0]) > 0) {
            this.modal.type = 'floor';
          }
          setTimeout(() => {
            const $winBlockExists = this.el.nativeElement.querySelector('.calculator .windows-block');
            if ($winBlockExists) {
              this.app.loading = false;
              this.app.scrollToElement('.calculator .windows-block');
            }
          }, 500);
        }
      });
    }
  }
  public openModalAddSeries = () => this.modal.type = 'addSeries';
  public openPreOrder = () => {
    if (!this.checkLocalStorageLifeTime()) {
      this.modal.type = 'lifeTimeReset';
      return false;
    }

    this.makeOrder();
    this.modal.type = 'preOrder';
  }
  public getPlanImg = () => {
    // tslint:disable-next-line:forin
    for (const ps in this.arPlans.room_plans) {
      const plans = this.arPlans.room_plans[ps];
      // tslint:disable-next-line:forin
      for (const p in plans) {
        const plan = plans[p];
        if (plan.code === this.planId) {
          return plan.picture;
        }
      }
    }
  }
  public makeOrder = () => {
    let installPrice = 0;
    this.order.fias = this.fias;
    this.order.series = this.arPlans.series_name;
    this.order.seriesId = this.seriesId;
    this.order.preOrderId = this.orderIdFromUrl;
    this.order.planId = this.plan.id;
    this.order.discount = this.http.discount.orderDiscount;
    this.order.planImg = this.getPlanImg();
    this.order.discharge = this.dischargePrice();

    this.order.statusDiscount = false;
    this.order.totalNoDiscount = this.planPrice();
    if (this.http.discount.check) {
      this.order.total = this.planPrice(true);
      this.order.statusDiscount = true;
    }
    else {
      this.order.total = this.planPrice();
    }
    this.order.delivery = this.deliveryPrice();
    this.order.lifting = this.plan.lifting;
    this.order.apertures = {};
    this.order.street = this.street;
    this.order.house = this.house;
    this.order.appartFloor = this.appartFloor.toString();
    // tslint:disable-next-line:forin
    for (const a in this.plan.apertures) {
      const aperture = this.plan.apertures[a];
      if (!aperture.install) {
        continue;
      }
      this.order.apertures[a] = {
        id: aperture.id,
        roomId: aperture.roomId,
        roomImg: aperture.roomImg,
        roomName: aperture.roomName,
        proportions: aperture.proportions,
      };
      // tslint:disable-next-line:forin
      for (const s in aperture.systems) {
        const system = aperture.systems[s];
        if (!system.active) {
          continue;
        }
        this.order.apertures[a].system = {
          id: s,
          description: system.description,
          img: system.img,
          name: system.name
        };
        // tslint:disable-next-line:forin
        for (const c in system.constructions) {
          const construction = system.constructions[c];
          if (!construction.active) {
            continue;
          }
          this.order.apertures[a].system.construction = {
            tag: c,
            id: construction.id,
            img: construction.img,
            smallImg: construction.smallImg,
            name: construction.name,
            systemId: construction.systemId,
            caseQuant: construction.caseQuant
          };
          if (construction.loggdecorType) {
            this.order.apertures[a].system.construction.loggdecorType = construction.loggdecorType;
          }
          const costs = {};
          let costsPrice = 0;
          // tslint:disable-next-line:forin
          for (const co in construction.costs) {
            const cost = construction.costs[co];
            if (!(cost.type === 'w-acc' || (cost.type === 'f-acc' && this.showFacing[a]))) {
              continue;
            }
            if (!cost.calculate) {
              continue;
            }
            // tslint:disable-next-line:forin
            for (const v in cost.values) {
              const value = cost.values[v];
              if (!value.active && !value.active2) {
                continue;
              }
              costs[value.costId] = value;
              costs[value.costId].sort = cost.sort;
              if (value.active2 != null) {
                costs[value.costId].matrix = cost.matrix;
              }
              if (!(value.costId === 'install')) {
                // tslint:disable-next-line:radix
                costsPrice += parseInt(value.price);
              }
              if (value.costId === 'install') {
                // tslint:disable-next-line:radix
                installPrice += parseInt(value.price);
              }
            }
          }
          this.order.apertures[a].system.construction.costs = costs;
          this.order.apertures[a].system.construction.price = costsPrice;
          this.order.install = installPrice;
        }
      }
    }
    localStorage.setItem('app.order', JSON.stringify(this.order));
    localStorage.setItem('app.plan', JSON.stringify(this.plan));
    return this.order;
  }

  public isEmailValid = (email: string) => {
    const reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/);
    return (email === '') ? false : (reg.test(email)) ? email : false;
  }
  public isPhoneValid = (phone: string) => {
    const reg = new RegExp(/^\+7 \(\d{3}\) \d{3}(-\d{2}){2}$/);
    return (phone === '') ? false : (reg.test(phone)) ? phone : false;
  }
  public preOrderExecute = (request) => {
    this.app.loading = true;
    this.http.preOrderExecute(request).subscribe(res => {
      if (res.status === 1 && res.preOrderId > 0) {
        this.preOrderForm.sended = true;
      }
      this.app.loading = false;
    });
  }

  public choosePlan = (planId) => {
    this.app.loading = true;
    this.planId = planId;
    this.showFacing = {};
    this.getPlan();
  }
  public aperture = (windowId: string) => {
    for (const a in this.plan.apertures) {
      if (a === windowId) {
        return this.plan.apertures[a];
      }
    }
  }
  public system = (windowId) => {
    // tslint:disable-next-line:forin
    for (const s in this.plan.apertures[windowId].systems) {
      const system = this.plan.apertures[windowId].systems[s];
      if (!system.active) {
        continue;
      }
      return system;
    }
  }
  public construction = (windowId) => {
    const system = this.system(windowId);
    // tslint:disable-next-line:forin
    for (const c in system.constructions) {
      const construction = system.constructions[c];
      if (!construction.active) {
        continue;
      }
      return construction;
    }
  }
  public lamination = (windowId) => (this.getCost(windowId, 'lamination'));
  public getCost = (windowId: string, costId: string): any => {
    const construction = this.construction(windowId);
    // tslint:disable-next-line:forin
    for (const co in construction.costs) {
      const cost = construction.costs[co];
      if (!(cost.id === costId)) {
        continue;
      }
      return cost;
    }
  }
  public discountReset() {
    this.http.discountReset();
    this.discountCheck = false;
    this.discountType = '';
    this.discountDescription = '';
    this.orderDiscount = null;
    this.order.discountType = "";
    this.order.discount = null;
  }
  public inArray = (needle: string, array: string[]): boolean => ((array.indexOf(needle) !== -1) ? true : false);
  public setActiveCountRooms(index) {
    this.activeCountRooms = index;
    this.detailedImages = {};
    this.createDetailImages(index);
    this.discountReset();
  }
  openModalDetailedImage(index) {
    let body = document.querySelector("body");
    body.classList.add("body-popup-overflow");
    this.plan3D_index = index;
    this.modal.type = 'detailedPlanImage';
  }
  public ota = (object: object): any[] => (this.app.ota(object));
  public sortObject = (object: object): any[] => (this.app.sortObject(object));
  public changeSystem = (apertureId, systemId: string, myconstruction) => {
    // tslint:disable-next-line:forin
    for (const s in this.aperture(apertureId).systems) {
      const system = this.aperture(apertureId).systems[s];
      if (systemId === s) {
        system.active = true;
        for (const c in system.constructions) {
          const construction = system.constructions[c];
          construction.active = false;
          if (construction.tag == myconstruction.tag) {
            construction.active = true;
          }
        }
        let noactive = true;
        for (const c in system.constructions) {
          const construction = system.constructions[c];
          if (construction.active) {
            noactive = false;
            break
          }
        }
        if (noactive) {
          for (const c in system.constructions) {
            system.constructions[c].active = true;
            break
          }
        }
      } else {
        system.active = false;
      }
    }
  }
  public console = (a: any): void => console.log(a);
  public windowsPrice = (): number => {
    let windowsPrice = 0;
    const facingsPrice = this.facingsPrice();
    // tslint:disable-next-line:forin
    for (const a in this.plan.apertures) {
      const aperture = this.plan.apertures[a];
      if (!aperture.install) {
        continue;
      }
      windowsPrice += this.windowPrice(a);
    }
    return windowsPrice + facingsPrice;
  }
  public windowPrice = (windowId: string): number => {
    let windowPrice = 0;
    // tslint:disable-next-line:forin
    for (const s in this.plan.apertures[windowId].systems) {
      const system = this.plan.apertures[windowId].systems[s];
      if (!system.active) {
        continue;
      }
      for (const c in system.constructions) {
        if (system.constructions[c].active) {
          const construction = system.constructions[c];
          // tslint:disable-next-line:forin
          for (const co in construction.costs) {

            const cost = construction.costs[co];

            if (!(cost.type === 'w-acc') || !cost.calculate) {
              continue;
            }

            // tslint:disable-next-line:forin
            for (const v in cost.values) {

              const value = cost.values[v];

              if (!value.active) {
                continue;
              }

              const amount = value.amount ? value.amount : 1;

              // tslint:disable-next-line:radix
              windowPrice += parseInt(value.price) * amount;
            }
          }
        }
      }
    }
    return windowPrice;
  }
  public facingsPrice = (): number => {
    let facingsPrice = 0;
    for (const a in this.showFacing) {
      if (!this.showFacing[a]) {
        continue;
      }
      facingsPrice += this.facingPrice(a);
    }
    return facingsPrice;
  }
  public facingPrice = (windowId: string): number => {
    let facingPrice = 0;
    // tslint:disable-next-line:forin
    for (const k in this.plan.apertures[windowId].systems) {
      const system = this.plan.apertures[windowId].systems[k];
      if (!system.active) {
        continue;
      }
      for (const m in system.constructions) {
        if (system.constructions[m].active) {
          const construction = system.constructions[m];
          // tslint:disable-next-line:forin
          for (const n in construction.costs) {
            const cost = construction.costs[n];
            if (!(cost.type === 'f-acc') || !cost.calculate) {
              continue;
            }
            // tslint:disable-next-line:forin
            for (const o in cost.values) {
              const value = cost.values[o];
              if (!value.active) {
                continue;
              }
              // tslint:disable-next-line:radix
              const amount: number = value.amount ? parseInt(value.amount) : 1;
              // tslint:disable-next-line:radix
              facingPrice += parseInt(value.price) * amount;
            }
          }
        }
      }
    }
    return facingPrice;
  }
  public planPrice = (makeDiscount: boolean = false): number => {
    const windowsPrice: number = this.windowsPrice();
    const dischargePrice: number = this.plan.delivery.active ? this.dischargePrice() : 0;
    const deliveryPrice: number = this.plan.delivery.active ? this.deliveryPrice() : 0;
    const liftingPrice: number = this.plan.lifting.active ? this.plan.lifting.value : 0;
    let discount: number = 0;
    if (makeDiscount) {
      discount = this.http.discount.orderDiscount ? this.http.discount.orderDiscount : 0;
    }
    const planPrice: number = Math.round((+windowsPrice + +deliveryPrice + +dischargePrice + +liftingPrice) * ((100 - discount) / 100));
    return planPrice;
  }
  public deliveryPrice = (): number => {
    let deliveryPrice = 0;
    let hasAdditionalDelivery = false;
    let frameQuants = 0;
    // tslint:disable-next-line:forin
    for (const a in this.plan.apertures) {
      const aperture = this.plan.apertures[a];
      if (!aperture.install) {
        continue;
      }
      const isBalcon = aperture.type === 'balcon';
      // tslint:disable-next-line:forin
      for (const s in aperture.systems) {
        const system = aperture.systems[s];
        if (!system.active) {
          continue;
        }
        // tslint:disable-next-line:forin
        for (const c in system.constructions) {
          const construction = system.constructions[c];
          if (!construction.active) {
            continue;
          }
          frameQuants += +construction.frameQuant;
          if (!isBalcon) {
            continue;
          }
          // tslint:disable-next-line:forin
          for (const co in construction.costs) {
            const cost = construction.costs[co];
            if (hasAdditionalDelivery) {
              continue;
            }
            /** Case first: add additional delivery price if timberframe exists */
            if (co === 'timberframe' && cost.type === 'w-acc') {
              hasAdditionalDelivery = true;
            }
            /** Case second: add additional delivery price if siding exists */
            if (co === 'siding' && cost.type === 'w-acc') {
              hasAdditionalDelivery = true;
            }
            /** Case third: add additional delivery price if showFacing is active */
            if (this.showFacing[a]) {
              hasAdditionalDelivery = true;
            }
          }
        }
      }
    }
    const delivery = (Math.floor(frameQuants / 5) + 1) * this.plan.delivery.value;
    deliveryPrice = hasAdditionalDelivery ? delivery + +this.plan.delivery2.value : delivery;
    return deliveryPrice;
  }
  public dischargePrice = (): number => {
    let dischargePrice = 0;
    // tslint:disable-next-line:forin
    for (const a in this.plan.apertures) {
      const aperture = this.plan.apertures[a];
      if (!aperture.install) {
        continue;
      }
      // tslint:disable-next-line:forin
      for (const s in aperture.systems) {
        const system = aperture.systems[s];
        if (!system.active) {
          continue;
        }
        // tslint:disable-next-line:forin
        for (const c in system.constructions) {
          const construction = system.constructions[c];
          if (!construction.active) {
            continue;
          }
          // tslint:disable-next-line:forin
          for (const co in construction.costs) {
            const cost = construction.costs[co];
            if (!(cost.id === 'discharge')) {
              continue;
            }
            // tslint:disable-next-line:forin
            for (const v in cost.values) {
              const value = cost.values[v];
              if (!value.active) {
                continue;
              }
              // tslint:disable-next-line:radix
              dischargePrice += parseInt(value.price);
            }
          }
        }
      }
    }
    return dischargePrice;
  }
  public closeModalWithOldConfig = () => {
    if (confirm(`Все изменения в конфигураторе не будут сохранены. Чтобы сохранить изменения нажмите кнопку
      "Сохранить изменения" в правом нижнем углу.`)) {
      this.plan.apertures[this.modal.windowId] = this.modal.windowData;
      this.closeModal();
    }
  }
  public changeCostValue = (costId: string, valueId: string) => {
    const cost = this.getCost(this.modal.windowId, costId);
    this.discountReset();
    // tslint:disable-next-line:forin
    for (const v in cost.values) {
      const value = cost.values[v];
      if (v === valueId) {
        value.active = true;
        continue;
      }
      value.active = false;
    }
  }
  public closeModal = () => {
    this.modal = {
      windowId: null,
      type: null,
      values: null,
      windowData: null
    };
  }
  public toggleOrderOption = (name) => {
    if (name === 'delivery') {
      this.plan.delivery.active = !this.plan.delivery.active;
      this.plan.lifting.active = !this.plan.lifting.active;
    }
  }
  public next = (windowId: string, step: number) => {
    const constructionsKeys: string[] =
      Object.keys(this.system(windowId).constructions);

    // tslint:disable-next-line:forin
    for (const k in constructionsKeys) {
      const currentTag = constructionsKeys[k];
      if (!(currentTag === this.construction(windowId).tag)) {
        continue;
      }
      const nextTag: string = constructionsKeys[+k + step];
      return this.system(windowId).constructions[nextTag];
    }
  }
  public changeAllSystems = () => {
    let systemId;
    this.discountReset();
    // tslint:disable-next-line:forin
    for (const s in this.modal.values.systems) {
      const system = this.modal.values.systems[s];
      if (system.active) {
        systemId = s;
        break;
      }
    }
    // tslint:disable-next-line:forin
    for (const a in this.plan.apertures) {
      const aperture = this.plan.apertures[a];
      if (!this.inArray(systemId, Object.keys(aperture.systems))) {
        continue;
      }
      // tslint:disable-next-line:forin
      for (const s in aperture.systems) {
        const system = aperture.systems[s];
        if (systemId === s) {
          system.active = true;
          continue;
        }
        system.active = false;
      }
    }
    this.closeModal();
  }

  public servicesPrice = () => this.deliveryPrice() + +this.plan.lifting.value + +this.dischargePrice();
}
