import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-lifting',
  templateUrl: './lifting.component.html',
  styleUrls: ['./lifting.component.scss']
})
export class LiftingComponent implements OnInit {

  constructor(public calculator: Calculator) { }

  ngOnInit() {}

  public setFloor = (floor) => {
    if (floor < 1 || floor > 20) {
      alert('Этаж введет некорректно! Этажность не должна быть меньше 0 и больше 19!');
      return;
    }
    let liftingPrice = 0;
    const apertures = this.calculator.plan.apertures;
    // tslint:disable-next-line:forin
    for (const a in apertures) {
      if (!apertures[a].install) {
        continue;
      }
      // tslint:disable-next-line:radix
      liftingPrice += parseInt(this.calculator.getCost(a, 'lifting').values[0]) * (floor - 1);
    }
    this.calculator.plan.lifting = {
      active: true,
      value: liftingPrice
    };
    this.calculator.modal = {};
  }
}
