import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
//import { CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';
import {ApertureComponent as Aperture} from '../aperture/aperture.component';
import { HttpService } from '../../services/http.service';
@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.scss'],
  preserveWhitespaces: true
})

export class CostComponent implements OnInit {

  @Input() construction: any;
  @Input() id: string;
  @Input() type: string;
  @Output() costTypeChanged = new EventEmitter<any>();

  public appConfig;

  constructor(private aperture: Aperture, 
    //private calc: Calculator,
    private http: HttpService) { }

  ngOnInit() {
    this.appConfig = this.aperture.appConfig;
  }

  public ota = (ob: any): any[] => (this.aperture.ota(ob));
  public cost = () => {
    // tslint:disable-next-line:forin
    for (const co in this.construction.costs) {
      const cost = this.construction.costs[co];
      if (cost.id === this.id) {
        return cost;
      }
    }
  }
  public costName = () => {
    const cost = this.cost();
    let valueName: string;
    // tslint:disable-next-line:forin
    for (const v in cost.values) {
      const value = cost.values[v];
      if (!value.active) {
        continue;
      }
      valueName = value.name;
    }
    if (cost.id === 'penoplexins' || cost.id === 'penofolins') {
      return 'Утепление лоджии ';
    }
    if (cost.id === 'system') {
      return this.construction.name + cost.name + valueName;
    }
    if (cost.id === 'lamination') {
      if (cost.type === 'w-opt') {
        return 'Изменить цвет профиля';
      }
    }
    return cost.name + valueName;
  }
  public costPrice(costId) {
    // tslint:disable-next-line:forin
    for (const co in this.construction.costs) {
      const cost = this.construction.costs[co];
      if (!(cost.id === costId)) {
        continue;
      }
      if (costId !== 'penoplexins') {
        // tslint:disable-next-line:forin
        for (const v in cost.values) {
          const value = cost.values[v];
          if (!value.active) {
            continue;
          }
          const amount = value.amount ? value.amount : 1;
          return value.price * amount;
        }
      }
      /** Calculate warming price */
      if (costId === 'penoplexins') {
        let penofolPrice = 0;
        // tslint:disable-next-line:forin
        for (const pe in this.construction.costs.penofolins.values) {
          const value = this.construction.costs.penofolins.values[pe];
          if (!value.active) {
            continue;
          }
          const amount = value.amount ? value.amount : 1;
          penofolPrice = value.price * amount;
        }
        // tslint:disable-next-line:forin
        for (const v in cost.values) {
          const value = cost.values[v];
          if (!value.active) {
            continue;
          }
          const amount = value.amount ? value.amount : 1;
          return (value.price * amount) + penofolPrice;
        }
      }
    }
  }
  public deleteCost = (): void => {
    if (!this.aperture.data.install) {
      return;
    }
    const cost = this.cost();
    if (cost.id === 'penoplexins') {
      cost.type = 'f-opt';
      this.construction.costs.penofolins.type = 'f-opt';
    }
    cost.type = cost.type === 'w-acc' ? 'w-opt' : 'f-opt';
    this.costTypeChanged.emit(cost);
  }
  public addCost = (): void => {
    if (!this.aperture.data.install) {
      return;
    }
    const cost = this.cost();
    if (cost.id === 'penoplexins') {
      cost.type = 'f-acc';
      this.construction.costs.penofolins.type = 'f-acc';
    }
    cost.type = cost.type === 'w-opt' ? 'w-acc' : 'f-acc';
    if (cost.id === 'lamination') {
      this.makeCostModal();
    }
    this.costTypeChanged.emit(cost);
  }
  public makeCostModal = (): void => {
    if (!this.aperture.data.install) {
      return;
    };
    const cost = this.cost();
    this.aperture.calculator.modal = {
      windowId: this.aperture.a,
      windowData: JSON.parse(JSON.stringify(this.aperture.plan.apertures[this.aperture.a])),
      type: cost.id
    };
  }
  public inArray = (needed: string, array: string[]): boolean => (this.aperture.inArray(needed, array));
  public countAccessories = (step) => {
    // tslint:disable-next-line:forin
    for (const v in this.cost().values) {
      const value = this.cost().values[v];
      if (!value.active) {
        continue;
      }
      // tslint:disable-next-line:radix
      if (parseInt(value.amount) + parseInt(step) > 0) {
        // tslint:disable-next-line:radix
        value.amount = parseInt(value.amount) + parseInt(step);
      }
    }
  }
}
