import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { CalculatorComponent } from './pages/calculator/calculator.component';
import { ApertureComponent } from './parts/aperture/aperture.component';
import { CostComponent } from './parts/cost/cost.component';
import { LaminationComponent } from './modals/lamination/lamination.component';
import { SystemsComponent } from './modals/systems/systems.component';
import { HandleComponent } from './modals/handle/handle.component';
import { SillComponent } from './modals/sill/sill.component';
import { FloorfinishComponent } from './modals/floorfinish/floorfinish.component';
import { ExtdoorsillComponent } from './modals/extdoorsill/extdoorsill.component';
import { ExtwindsillComponent } from './modals/extwindsill/extwindsill.component';
import { LoggdecorComponent } from './modals/loggdecor/loggdecor.component';
import { WarmingComponent } from './modals/warming/warming.component';
import { CostructionsComponent } from './modals/costructions/costructions.component';
import { PreloaderComponent } from './modals/preloader/preloader.component';
import { IndexComponent } from './pages/index/index.component';
import { DadataFormComponent } from './parts/dadata-form/dadata-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgxMaskModule } from 'ngx-mask';
import { AddSeriesComponent } from './modals/add-series/add-series.component';
import { PreOrderComponent } from './modals/pre-order/pre-order.component';
import { OrderComponent } from './pages/order/order.component';
import { P404Component } from './pages/p404/p404.component';
import { ModalSearchComponent } from './modals/modal-search/modal-search.component';
import { CostModalComponent } from './parts/cost-modal/cost-modal.component';
import { LiftingComponent } from './modals/lifting/lifting.component';
import { ResetComponent } from './modals/resetModal/reset.component';
import { PlanComponent } from './pages/p404/include/plan/plan.component';
import { VirtualComponent } from './pages/virtual/virtual.component';
import { DetailedPlanImageComponent } from './modals/detailed-plan-image/detailed-plan-image.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CalculatorComponent,
    ApertureComponent,
    CostComponent,
    LaminationComponent,
    SystemsComponent,
    HandleComponent,
    SillComponent,
    FloorfinishComponent,
    ExtdoorsillComponent,
    ExtwindsillComponent,
    LoggdecorComponent,
    WarmingComponent,
    DetailedPlanImageComponent,
    CostructionsComponent,
    PreloaderComponent,
    IndexComponent,
    DadataFormComponent,
    AddSeriesComponent,
    PreOrderComponent,
    OrderComponent,
    P404Component,
    ModalSearchComponent,
    CostModalComponent,
    LiftingComponent,
    ResetComponent,
    PlanComponent,
    VirtualComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGalleryModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
