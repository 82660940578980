import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-costructions',
  templateUrl: './costructions.component.html',
  styleUrls: ['./costructions.component.scss']
})
export class CostructionsComponent implements OnInit {

  @Input() modal;
  public appConfig;

  constructor(private calculator: Calculator) { }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public closeModal = () => this.calculator.closeModal();
  public closeModalWithOldConfig = () => this.calculator.closeModalWithOldConfig();
  public windowPrice = () => (this.calculator.windowPrice(this.modal.windowId));
  public system = () => (this.calculator.system(this.modal.windowId));
  public chooseTypeOpen = (constructionId: string) => {
    // tslint:disable-next-line:forin
    for (const c in this.system().constructions) {
      const construction = this.system().constructions[c];
      if (construction.id === constructionId) {
        construction.active = true;
        continue;
      }
      construction.active = false;
    }
  }
  public ota = (o) => (this.calculator.ota(o));
  public sortObject = (o) => (this.calculator.sortObject(o));
}
