import {Component, OnInit} from '@angular/core';
import {AppComponent as App} from '../../app.component';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'virtual',
    templateUrl: './virtual.component.html',
    styleUrls: ['./virtual.component.scss']
})
export class VirtualComponent implements OnInit {

    public houseType = null;
    public house = null;
    public apertureList = [];
    public aperturesLink = '';
    public queryParams: any = {};
    public houseTypeName: string;
    public apertures: any;
    public balkons: any;
    public series: string;
    public isVirtual = true;

    constructor(private app: App, private route: ActivatedRoute) {
    }

    ngOnInit() {
        let slag = this.route.snapshot.url[1].path;

        this.setHouseType(slag);

        // 3.3
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            eCategory: 'calculator',
            eAction: 'openCalcAddressNotFound',
            eLabel: '',
            eNI: true,
            event: 'GAEvent'
        });

        this.app.loading = false;
    }

    public getVirtualSeriesData = () => {
        return JSON.parse(localStorage.getItem("virtualData"));
    }

    public setHouseType = (type) => {
        const apertures = this.getVirtualSeriesData();

        this.houseType  = type;
        this.house = apertures.home;
        this.houseTypeName = apertures.name;
        this.apertures = apertures.windows;
        this.balkons = apertures.balkons;
        this.series = apertures.series;
    }

    public genAperturesLink = () => {
        let sectionMiddle = this.series + "/" + this.house;
        this.queryParams = {custom: 1};

        // tslint:disable-next-line:forin
        for (const k in this.apertureList) {
            // tslint:disable-next-line:variable-name forin
            for (const _k in this.apertureList[k]) {
                this.queryParams[_k] = this.apertureList[k][_k];
            }
        }
        this.aperturesLink = this.apertureList.length === 0 ?
            // null : '/house/raschet-ceny-osteklenya-kvartiry/' + sectionMiddle + sectionLast;
            null : '/house/raschet-ceny-osteklenya-kvartiry/' + sectionMiddle;
    }
    public toggleAperture = (type, code) => {
      let apertures_and_balkons = [...this.apertures, ...this.balkons];
        // tslint:disable-next-line:forin
        for (const k in apertures_and_balkons) {
            const aperture = apertures_and_balkons[k];
            if (!(aperture.code === code)) {
                continue;
            }
            aperture.active = !aperture.active;
            if (aperture.active) {
                const obj = {};
                obj[code] = 1;
                this.apertureList.push(obj);
            }
            if (!aperture.active) {
                // tslint:disable-next-line:no-shadowed-variable
                for (const k in this.apertureList) {
                    if (this.apertureList[k][code]) {
                        delete this.apertureList[k];
                        this.apertureList = this.apertureList.filter(item => item);
                        aperture.count = 1;
                        break;
                    }
                }
            }
        }
        this.genAperturesLink();
    }
    public setApertureQuantity = (type, code, step) => {
        // tslint:disable-next-line:forin
        console.log(type, code, step);
        for (const k in this.apertureList) {
            if (this.apertureList[k][code] === 1 && step < 0) {
                return;
            }
            if (this.apertureList[k][code]) {
                this.apertureList[k][code] = this.apertureList[k][code] + step;
                break;
            }
        }
        for (const k in this.apertures) {
            if (this.apertures[k].code === code) {
                this.apertures[k].count = this.apertures[k].count + step;
                break;
            }
        }
        this.genAperturesLink();
    }
}
