import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalculatorComponent } from '../app/pages/calculator/calculator.component';
import {IndexComponent} from './pages/index/index.component';
import {OrderComponent} from './pages/order/order.component';
import {P404Component} from './pages/p404/p404.component';
import {VirtualComponent} from './pages/virtual/virtual.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


const routes: Routes = [
  { path: 'order', component: OrderComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'house/raschet-ceny-osteklenya-kvartiry/:seriesId', component: CalculatorComponent },
  { path: 'house/raschet-ceny-osteklenya-kvartiry/:seriesId/:planId', component: CalculatorComponent },
  { path: 'notfound', component: P404Component },
  { path: 'virtual/:seriesId', component: VirtualComponent },
  { path: 'notfound/common', component: P404Component },
  { path: 'notfound/pik1', component: P404Component },
  { path: 'notfound/pik2', component: P404Component },
  { path: 'notfound/pik3', component: P404Component },
  { path: 'notfound/spb1lg507', component: P404Component },
  { path: 'notfound/sakb21900', component: P404Component },
  { path: 'notfound/:houseType', redirectTo: '/notfound/common', pathMatch: 'full' },
  { path: '', component: IndexComponent },
  { path: '**', component: P404Component }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
