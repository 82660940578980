import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-warming',
  templateUrl: './warming.component.html',
  styleUrls: ['./warming.component.scss']
})
export class WarmingComponent implements OnInit {

  @Input() modal;

  public appConfig;

  constructor(private calculator: Calculator) { }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public closeModal = () => this.calculator.closeModal();
  public closeModalWithOldConfig = () => this.calculator.closeModalWithOldConfig();
  public getCost = () => (this.calculator.getCost(this.modal.windowId, 'extwindsill'));
  public changeCostValue = (valueId: number) => this.calculator.changeCostValue('extwindsill', valueId.toString());
  public construction = () => (this.calculator.construction(this.modal.windowId));
  public sortObject = (object) => (this.calculator.sortObject(object));
  public getCosts = (): ([] | any) => {
    const costs = this.sortObject(this.construction().costs);
    const result = Object.keys(costs).map((i) => {
      const cost = costs[i];
      return cost;
    });
    return result;
  }
  public toggleWarming = (costId: string) => {
    const mess = 'Вы не можете удалить второй вид "Утепления". Вернитесь в список опций и удалите "Утепление" из раздела ' +
      '"Что входит в отделку лоджии"';
    // tslint:disable-next-line:forin
    for (const co in this.construction().costs) {
      const cost = this.construction().costs[co];
      if (!(cost.id === costId)) {
        continue;
      }
      if (costId === 'penoplexins') {
        if (!this.construction().costs.penofolins.values[0].active) {
          alert(mess);
          return;
        }
        cost.show = !cost.show;
        this.construction().costs.penofolins.show = !cost.show ? true : false;
      }
      if (costId === 'penofolins') {
        if (!this.construction().costs.penoplexins.values[0].active) {
          alert(mess);
          return;
        }
      }
      // tslint:disable-next-line:forin
      for (const v in cost.values) {
        const value = cost.values[v];
        if (value.costId === costId) {
          value.active = !value.active;
        }
      }
    }
  }
  public toggleWarmActive = (costId: string) => {
    // tslint:disable-next-line:forin
    for (const co in this.construction().costs) {
      const cost = this.construction().costs[co];
      if (cost.choosen == null) {
        continue;
      }
      cost.choosen = cost.id === costId ? true : false;
    }
  }
}
