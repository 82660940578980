import {Component, Input, OnInit} from '@angular/core';
import { CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';
import {CostComponent} from "../cost/cost.component";

interface ShowFacing {
  windowId?: string;
  value?: boolean;
}

interface Plan {
  apertures: object;
}

interface Aperture {
  install: boolean;
  type: ('balcon' | 'window');
  roomName: string;
  proportions: string;
  systems: object;
  roomImg: string;
  id: string;
}

interface System {
  constructions: { Construction };
  name: string;
}

interface Construction {
  active: boolean;
  name: string;
  smallImg: string;
  img: string;
  id: string;
  tag: string;
  costs: { Cost };
}

interface Cost {
  name: string;
  show: boolean;
  type: string;
}

@Component({
  selector: 'app-aperture',
  templateUrl: './aperture.component.html',
  styleUrls: ['./aperture.component.scss']
})
export class ApertureComponent implements OnInit {

  @Input() plan: Plan;
  @Input() a: string;
  @Input() showFacing: ShowFacing;
  @Input() modal: any;
  @Input() custom: boolean;

  public changedCostType = [];
  public showOpenTypes = false;
  public data: Aperture;
  public appConfig;

  constructor(public calculator: Calculator) { }

  ngOnInit() {
    this.data = this.plan.apertures[this.a];
    this.appConfig = this.calculator.appConfig;
  }

  public ota = (ob: any): any[] => (this.calculator.ota(ob));
  public inArray = (needed: string, array: string[]): boolean => (this.calculator.inArray(needed, array));
  public next = (step: number): any => {
    return this.calculator.next(this.a.toString(), step);
  }
  public aperture = (): Aperture => {
    const aperture = this.plan.apertures[this.a];
    aperture.roomName = aperture.type === 'balcon' ? 'Лоджия' : aperture.roomName;
    return aperture;
  }
  public system = (): System => {
    const aperture = this.aperture();

    // tslint:disable-next-line:forin
    for (const s in aperture.systems) {
      const system = aperture.systems[s];
      if (!system.active) {
        continue;
      }
      return system;
    }
  }
  public construction = (): Construction => {
    const system = this.system();
    // tslint:disable-next-line:forin
    for (const c in system.constructions) {
      const construction = system.constructions[c];
      if (!construction.active) {
        continue;
      }
      return construction;
    }
  }
  public costTypeChanged(cost:any){
    this.calculator.discountReset();
    if (this.changedCostType.find(el => el.id === cost.id)) {
      this.changedCostType.find(el => el.id === cost.id).type = cost.type;
    }
    else{
      this.changedCostType.push(cost);
    }
  }
  public getCosts = (type: string): any[] => {
    if (this.ota(this.construction())) {
      let allCosts = this.ota(this.construction().costs);
      if (!!this.changedCostType.length) {
        this.changedCostType.forEach((element) => {
          allCosts.find(el => el.id === element.id).type = element.type;
        })
      }
  
  
      const res = [];
      for (const co in allCosts) {
        if (allCosts[co].show && allCosts[co].type === type) {
          res.push(allCosts[co]);
        }
      }
  
  
      //if (this.costTypeChanged) res.push(this.costTypeChanged);
      return res;
    }
  }
  public toggleWindow = (windowId: string): void => {
this.calculator.discountReset();
    this.plan.apertures[windowId].install = !this.plan.apertures[windowId].install;

    if (this.plan.apertures[windowId].install === false) {
      this.showFacing[windowId] = false;
    }
  }
  public toggleFacing = (windowId) => {
    if (!this.plan.apertures[windowId].install) {
      return;
    }
    this.showFacing[windowId] = !Boolean(this.showFacing[windowId]);
  }
  public exist3d = (): boolean => {
    const construction = this.construction();
    if (construction) {
      if (!construction.img) {
        return false;
      }
      const arr = construction.img.substring(1, construction.img.length).split('/');
      return arr[arr.length - 1].toLowerCase().includes('.gif');
    }
  }
  public show3d = (): void => {
    const construction = this.construction();
    const img = construction.smallImg;
    if (this.exist3d()) {
      construction.smallImg = construction.img;
      setTimeout(() => {
        construction.smallImg = img;
      }, 5000);
    }
  }
  public changeConstruction = (step: number): void => {
    if (!this.next(step)) {
      return;
    }
    const currentConstruction =
      this.system().constructions[this.construction().tag];

    const newConstruction =
      this.system().constructions[this.next(step).tag];

    currentConstruction.active = false;
    newConstruction.active = true;
  }
  public constructionImg = (): string => {
    const construction = this.construction();
    return 'https://lk.azbukaokna.ru' + construction.smallImg;
  }
  public windowPrice = (windowId: string): number => (this.calculator.windowPrice(windowId));
  public facingPrice = (windowId: string): number => (this.calculator.facingPrice(windowId));
  public changeSystem = (systemId: string) => {
    let construction = this.construction();
    if (this.ota(this.aperture().systems).length === 1) {
      return;
    }
    this.calculator.changeSystem(this.a.toString(), systemId, construction);
  }
  public makeSystemModal = () => {
    const aperture = this.aperture();
    if (!aperture.install) {
      return;
    }
    this.modal.windowId = this.a;
    this.modal.windowData = JSON.parse(JSON.stringify(aperture));
    this.modal.type = 'systems';
    this.modal.values = aperture;
  }
  public sortObject = (o) => (this.calculator.sortObject(o));
}
