import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';
import {Systems as SystemData} from '../../data/systems';
import {DomSanitizer} from '@angular/platform-browser';

interface Aperture {
  install: boolean;
  type: ('balcon' | 'window');
  roomName: string;
  systems: object;
  roomImg: string;
  id: string;
}

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements OnInit {

  @Input() modal;

  public systemModal = {
    compareMode: false,
    systemCount: 0,
    provedalExists: false
  };
  public sliderStack = null;
  public windowWidth: number;
  public systemDropMenu: boolean;
  public systemModalPopup: boolean;
  public aperture: Aperture;
  public systemsData: any;

  constructor(private calculator: Calculator, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.aperture = this.calculator.plan.apertures[this.modal.windowId];
    this.systemModal.compareMode = false;
    this.systemModal.systemCount = Object.keys(this.aperture.systems).length;
    for (const s in this.aperture.systems) {
      if (!(s === '8')) {
        continue;
      }
      this.systemModal.provedalExists = true;
      break;
    }
    this.slider(0);
    this.systemDropMenu = false;
    this.windowWidth = window.innerWidth;
    this.systemsData = new SystemData().data;
    this.systemModalPopup = false;
    this.sliderInit();
  }
  
  public sliderInit = () => {
    const windowWidth = this.windowWidth;
    if (this.sliderStack === null) {
      // 992+ & provedal || 768+ & !provedal
      if ((windowWidth > 992 && this.systemModal.provedalExists) ||
        (windowWidth > 768 && windowWidth <= 992 && !this.systemModal.provedalExists)) {
        this.sliderStack = {
          left: 0,
          max: 1
        };
      }
      // 768+ & provedal
      if (windowWidth > 768 && windowWidth <= 992 && this.systemModal.provedalExists) {
        this.sliderStack = {
          left: 0,
          max: 2
        };
      }
    }
  }

  public slider = (step) => {
    if (step === 0) {
      return;
    }
    const res = this.sliderStack.left + step;

    if (res > this.sliderStack.max || res < 0) {
      return;
    }
    this.sliderStack.left = this.sliderStack.left + step;
  }

  public next = (step) => {
    return this.calculator.next(this.modal.windowId.toString(), step);
  }

  public systemClass = (id: string) => {
    const systems = this.aperture.systems;
    const system = systems[id];

    const active = system.active ? 'active' : '';
    const last = !this.next(1) ? 'new-modal__tab_last' : '';

    return `new-modal__tab_${id} ${active} ${last}`;
  }

  public changeSystem = (systemId: string) => {
    let construction = this.calculator.construction(this.modal.windowId);
    this.calculator.changeSystem(this.modal.windowId.toString(), systemId, construction);
  }

  public getSystemData = (id: string) => {
    const systems = this.systemsData;
    for (const s in systems) {
      if (id === systems[s].id) {
        return systems[s];
      }
    }
  }

  public sortObject = (object) => (this.calculator.sortObject(object));
  public console = (a) => this.calculator.console(a);

  public getCost = () => {
    return this.calculator.getCost(this.modal.windowId, 'system');
  }

  public sliderStyle = () => {
    if (this.windowWidth > 992 && this.systemModal.provedalExists) {
      return this.sanitizer.bypassSecurityTrustStyle(`left: -${this.sliderStack.left * 192}px`);
    }
    if ((this.windowWidth > 768 && this.windowWidth <= 992 && this.systemModal.provedalExists) ||
      (this.windowWidth > 768 && this.windowWidth <= 992 && !this.systemModal.provedalExists)) {
      return this.sanitizer.bypassSecurityTrustStyle(`left: -${this.sliderStack.left * 156}px`);
    }
  }

  public getSystemBg = (id: string) => {
    return this.sanitizer.bypassSecurityTrustStyle(`background-image: url(assets/imgs/accessories/${id}.png)`);
  }

  public constructionPrice = (systemId) => {
    const aperture = this.calculator.plan.apertures[this.modal.windowId];

    // tslint:disable-next-line:forin
    for (const s in aperture.systems) {
      const system = aperture.systems[s];
      if (!(system.id === systemId)) {
        continue;
      }

      // tslint:disable-next-line:forin
      for (const c in system.constructions) {
        const construction = system.constructions[c];
        if (!construction.active) {
          continue;
        }

        // tslint:disable-next-line:forin
        for (const co in construction.costs) {
          const cost = construction.costs[co];
          if (!(cost.id === 'system')) {
            continue;
          }

          // tslint:disable-next-line:forin
          for (const v in cost.values) {
            const value = cost.values[v];
            if (!value.active) {
              continue;
            }
            return value.price;
          }
        }
      }
    }
  }

}
