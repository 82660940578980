import { Component, OnInit } from '@angular/core';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public showMobileMenu = false;

  constructor(public app: AppComponent) { }

  ngOnInit() {
  }

  public toggleMobileMenu = () => {
    this.showMobileMenu = !this.showMobileMenu;
  }

  public clickToLink = () => {
    if (this.showMobileMenu) {
      this.toggleMobileMenu();
    }
  }

}
