export class Pik3Apertures {
  public data = [
    {
      name : 'Одностворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4325.png',
      code : '4325',
      active : false,
      count : 1
    },
    {
      name : 'Одностворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4321.png',
      code : '4321',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4323.png',
      code : '4323',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4327.png',
      code : '4327',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой среднее',
      img : 'assets/apimgs/4324.png',
      code : '4324',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой среднее',
      img : 'assets/apimgs/4328.png',
      code : '4328',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4322.png',
      code : '4322',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4326.png',
      code : '4326',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок малый',
      img : 'assets/apimgs/4336.png',
      code : '4336',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок большой',
      img : 'assets/apimgs/4335.png',
      code : '4335',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок с окном до пола',
      img : 'assets/apimgs/4337.png',
      code : '4337',
      active : false,
      count : 1
    }
  ];
  public name = 'Серия домов ПИК-3';
}
