import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

interface Dates {
  delivery?: any;
  install?: any;
}

interface SmsResponse {
  smsCode?: any;
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
  private siteId = 'rh';
  private apiUrl = 'https://lk.azbukaokna.ru';
  public discount: any ={
    check: false,
    type: '',
    description: '',
    orderDiscount: 0
  }
  private httpOptions: any = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })
  };
  constructor(private httpClient: HttpClient) {}
  public discountReset(){
    this.discount.discountCheck = false;
    this.discount.discountType='';
    this.discount.discountDescription='';
    this.discount.orderDiscount=null;
  }
  public getPlan = (planId: string, custom: string = ''): Observable<any> => {
    const request = `${this.apiUrl}/api20/calculator/getInfoByPlanIDForTradeMark/?planId=${planId}${custom}&tradeMark=${this.siteId}`;
    return this.httpClient.get(request).pipe(map(data => {
      return data;
    }));
  }
  public getPlans = (seriesId: string): Observable<any> => {
    const action = 'getHousePlans';
    const request = `${this.apiUrl}/API/?action=${action}&series=${seriesId}`;
    return this.httpClient.get(request).pipe(map(data => {
      return data;
    }));
  }
  public getDataFromDadata(query: string) {
    const apiKey = 'eefe28ba52dd44c247870680c7bcafb2faf676a6';
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + apiKey,
      })
    };
    const type = 'address';
    const count = 25;
    const locations = [{region: 'москва'}, {region: 'московская', }];
    const body = {
      query
    };

    return this.httpClient.post('https://old.mosokna.ru/api20/mosokna/getDataFromDadata/', body, httpOptions);
  }
  public getHouseInfoByAddress = (address: string) => {
    const action = 'getHouseInfoByAddress';
    const res = this.httpClient.post(
      `https://lk.azbukaokna.ru/API/?action=${action}&address=${encodeURI(address)}&site=S`,
      this.httpOptions);
    return res;
  }
  public getVirtualData = (series: string) => {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token eefe28ba52dd44c247870680c7bcafb2faf676a6',
      })
    };

    const body = series;
    const res = this.httpClient.post(`https://old.mosokna.ru/api20/mosokna/getSeriesInfo/`, body, httpOptions);
    return res;
  }
  public addReview = (request) => (this.httpClient.post(this.apiUrl + '/api20/form/addReview/', request));
  public getDate = (): Observable<any> => {

    const formData = new FormData();
    formData.append('action', 'getDates');

    return this.httpClient
      .post(this.apiUrl + '/API/ORDER/', formData)
      .pipe(map(res => {

        const dates: Dates = {};
        const transform = JSON.parse(JSON.stringify(res));

        const deliveryDate = transform.MIN_DELIV_DATE.split('.');
        const installDate = transform.MIN_DATE.split('.');

        dates.delivery = { year: deliveryDate[2], month: deliveryDate[1], day: deliveryDate[0] };
        dates.install = { year: installDate[2], month: installDate[1], day: installDate[0] };

        return dates;
      }));
  }
  public getSms = (formData): Observable<any> => {
    return this.httpClient.post('https://lk.azbukaokna.ru/API/ORDER/', formData)
      .pipe(map((res: SmsResponse) => {
        if (res) {
          return res.smsCode;
        }
      }));
  }
  public preOrderExecute = (request): Observable<any> => {
    return this.httpClient.post(this.apiUrl + '/api20/order/preOrderExecute/', request)
      .pipe(map(res => (res)));
  }
  public orderExecute = (request: any): Observable<any> => {
    return this.httpClient.post(this.apiUrl + '/api20/order/orderExecute/', request)
      .pipe(map(res => (res)));
  }
  public updateDetailImage = (planId: string): Observable<any> => {
    const request = `${this.apiUrl}/api20/calculator/get3DImage/?planId=${planId}`;
      return this.httpClient.get(request).pipe(map(data => {
        return data; // .$set(_this.detailedImages, elem.code, data.soap3dImage ? 'data:image/png;base64,' + data.soap3dImage : false);
      }));
  }
}
