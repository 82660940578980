export class Pik2Apertures {
  public data = [
    {
      name : 'Одностворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4320.png',
      code : '4320',
      active : false,
      count : 1
    },
    {
      name : 'Одностворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4316.png',
      code : '4316',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4318.png',
      code : '4318',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой среднее',
      img : 'assets/apimgs/4319.png',
      code : '4319',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4317.png',
      code : '4317',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок малый',
      img : 'assets/apimgs/4333.png',
      code : '4333',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок большой',
      img : 'assets/apimgs/4332.png',
      code : '4332',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок с окном до пола',
      img : 'assets/apimgs/4334.png',
      code : '4334',
      active : false,
      count : 1
    }
  ];
  public name = 'Серия домов ПИК-2';
}
