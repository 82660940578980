import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-lamination',
  templateUrl: './lamination.component.html',
  styleUrls: ['./lamination.component.scss']
})
export class LaminationComponent implements OnInit {

  @Input() modal;

  public appConfig: any;

  constructor(private calculator: Calculator) {
  }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public lamination = (windowId: string) => (this.calculator.lamination(windowId));
  public ota = (object: {}) => (this.calculator.ota(object));
  public inArray = (needle: string, array: string[]) => (this.calculator.inArray(needle, array));
  public windowPrice = (windowId: string) => (this.calculator.windowPrice(windowId));
  public closeModal = () => {
    this.calculator.closeModal();
  }
  public closeModalWithOldConfig = () => {
    this.calculator.closeModalWithOldConfig();
  }

  public chooseLamType = (lamType) => {
    const cost = this.lamination(this.modal.windowId);
    let color;
    // tslint:disable-next-line:forin
    for (const v in cost.values) {
      const value = cost.values[v];

      value.price = lamType === 'single' ? cost.matrix.price1 : cost.matrix.price2;

      if (value.active) {
        color = value.id;
      }
    }
    cost.matrix.type = lamType;

    if (lamType === 'single') {
      this.setLamValue(color, 0);
      cost.matrix.changable = 'single_in';
      cost.matrix.in = color;
      cost.matrix.out = 0;
    }

    if (lamType === 'double') {
      this.setLamValue(color, color);
      cost.matrix.changable = 'double_both';
      cost.matrix.in = color;
      cost.matrix.out = color;
    }
  }
  public chooseLamSide = (lamSide) => {
    let color;
    let color2;
    const cost = this.lamination(this.modal.windowId);

    // tslint:disable-next-line:forin
    for (const v in cost.values) {

      const value = cost.values[v];

      if (value.active) {
        color = value.id;
      }

      if (value.active2) {
        color2 = value.id;
      }
    }

    if (lamSide === 'single_in') {
      cost.matrix.in = color;
      cost.matrix.out = 0;
    }

    if (lamSide === 'single_out') {
      cost.matrix.in = 0;
      cost.matrix.out = color;
    }

    if (lamSide === 'double_out') {
      cost.matrix.in = color;
      cost.matrix.out = color2;
    }

    cost.matrix.changable = lamSide;
  }
  public chooseLamColor = (valueId) => {
    const cost = this.lamination(this.modal.windowId);

    const changable = cost.matrix.changable;

    if (cost.matrix.type === 'single') {
      if (changable === 'single_in') {
        cost.matrix.in = valueId;
        cost.matrix.out = 0;
        this.setLamValue(valueId, null);
      }

      if (changable === 'single_out') {
        cost.matrix.in = 0;
        cost.matrix.out = valueId;
        this.setLamValue(null, valueId);
      }
    }

    if (cost.matrix.type === 'double') {
      if (changable === 'double_in' || changable === 'double_both') {

        if (changable === 'double_in') {
          cost.matrix.in = valueId;
          this.setLamValue(valueId, cost.matrix.out);
        }

        if (changable === 'double_both') {
          cost.matrix.in = valueId;
          cost.matrix.out = valueId;
          this.setLamValue(valueId, !(cost.matrix.out === 0) ? cost.matrix.out : valueId);
        }
      }

      if (changable === 'double_out') {
        cost.matrix.out = valueId;
        this.setLamValue(cost.matrix.in, valueId);
      }
    }
  }
  public chooseSidesType = (sidesType) => {
    const cost = this.lamination(this.modal.windowId);
    cost.matrix.changable = sidesType;

    let color;
    let color2;

    color = color2 = 0;

    // tslint:disable-next-line:forin
    for (const v in cost.values) {

      const value = cost.values[v];

      if (value.active) {
        color = value.id;
      }

      if (value.active2) {
        color2 = value.id;
      }
    }

    cost.matrix.in = color;
    cost.matrix.out = color2;

    if (cost.matrix.changable === 'double_both') {
      this.setLamValue(color, color);
    }
  }
  public setLamValue = (activeOne, activeTwo) => {
    const cost = this.lamination(this.modal.windowId);

    // tslint:disable-next-line:forin
    for (const v in cost.values) {
      const value = cost.values[v];
      value.active = value.id === activeOne;
      value.active2 = value.id === activeTwo;
    }
  }
}
