import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {
  constructor(public app: AppComponent) { }
  ngOnInit() {
    setTimeout(() => { this.app.loading = false; }, 1000);
  }
}
