import { Component, OnInit } from '@angular/core';
import { AppComponent as App} from '../../app.component';
import { PanelApertures } from './data/panel-apertures';
import { BricksApertures } from './data/bricks-apertures';
import { StalinApertures } from './data/stalin-apertures';
import { NewApertures } from './data/new-apertures';
import { Pik1Apertures } from './data/pik1-apertures';
import { Pik2Apertures } from './data/pik2-apertures';
import { Pik3Apertures } from './data/pik3-apertures';
import { Spb1LgApertures } from './data/spb1lg-apertures';
import { Sakb2Apertures } from "./data/sakb2-apertures";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.scss']
})
export class P404Component implements OnInit {

  public houseType = null;
  public apertureList = [];
  public aperturesLink = '';
  public queryParams: any = {};
  public houseTypeName: string;
  public apertures: any;
  public balkons: any;
  public isVirtual = false;

  constructor(private app: App, private route: ActivatedRoute) {  }

  ngOnInit() {
    let slag: string;
    let matched: string;
    if (this.route.snapshot.url[1]) {
      slag = this.route.snapshot.url[1].path;
      matched = ['pik1', 'pik2', 'pik3', 'spb1lg507', 'sakb21900'].find(el => el === slag);
    }
    if (matched) {
      this.houseType = slag;
      this.setHouseType(slag);
    }

    // 3.3
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      eCategory: 'calculator',
      eAction: 'openCalcAddressNotFound',
      eLabel: '',
      eNI: true,
      event: 'GAEvent'
    });

    this.app.loading = false;
  }

  public makeApertures = (type) => {
    switch (type) {
      case 'panel':
        return this.getVirtualSeriesData('panelnyy-dom');
        break;
      case 'bricks':
        return this.getVirtualSeriesData('kirpichnyy-dom');
        break;
      case 'stalin':
        return this.getVirtualSeriesData('stalinskiy-dom');
        break;
      case 'new':
        return this.getVirtualSeriesData('novostroyka');
        break;
      case 'pik1':
        return new Pik1Apertures();
        break;
      case 'pik2':
        return new Pik2Apertures();
        break;
      case 'pik3':
        return new Pik3Apertures();
        break;
      case 'spb1lg507':
        return new Spb1LgApertures();
        break;
      case 'sakb21900':
        return new Sakb2Apertures();
        break;
      default:
        break;
    }
  }

  public getVirtualSeriesData = (type) => {
    let item = JSON.parse(localStorage.getItem(type));
    localStorage.removeItem('virtualData');
    localStorage.setItem('virtualData', localStorage.getItem(type));
    localStorage.setItem('backUrl', '/notfound/common');
    item.data = item.windows;
    delete item.windows;
    return item;
  }
  public setHouseType = (type) => {
    const apertures = this.makeApertures(type);
    this.houseType = type;
    this.houseTypeName = apertures.name;
    this.apertures = apertures.data;
    this.apertures.map(function(item, i) {
      item.img = item.img.replace(/ /g, '%20');
    });
    this.balkons = apertures.balkons;
    this.balkons.map(function(item, i) {
      item.img = item.img.replace(/ /g, '%20');
    });
  }
  public genAperturesLink = () => {
    let sectionMiddle = '';
    this.queryParams = {custom : 1};
    switch (this.houseType) {
      case 'panel':
        sectionMiddle = 'panelnyy-dom/7340';
        break;
      case 'bricks':
        sectionMiddle = 'kirpichnyy-dom/7341';
        break;
      case 'stalin':
        sectionMiddle = 'stalinskiy-dom/7342';
        break;
      case 'new':
        sectionMiddle = 'novostroyka/7343';
        break;
      case 'pik1':
        sectionMiddle = 'pik-1/5049';
        break;
      case 'pik2':
        sectionMiddle = 'pik-2/5050';
        break;
      case 'pik3':
        sectionMiddle = 'pik-3/5051';
        break;
      case 'spb1lg507':
        sectionMiddle = 's-pb-1lg-507/6971';
        break;
      case 'sakb21900':
        sectionMiddle = 'sakb-2-1900/4465';
        break;
    }

    // tslint:disable-next-line:forin
    for (const k in this.apertureList) {
      // tslint:disable-next-line:variable-name forin
      for (const _k in this.apertureList[k]) {
        this.queryParams[_k] = this.apertureList[k][_k];
      }
    }
    this.aperturesLink = this.apertureList.length === 0 ?
      // null : '/house/raschet-ceny-osteklenya-kvartiry/' + sectionMiddle + sectionLast;
      null : '/house/raschet-ceny-osteklenya-kvartiry/' + sectionMiddle;
  }
  public toggleAperture = (type, code) => {
    let apertures_and_balkons = [...this.apertures, ...this.balkons];

    // tslint:disable-next-line:forin
    for (const k in apertures_and_balkons) {
      const aperture = apertures_and_balkons[k];
      if (!(aperture.code === code)) {
        continue;
      }
      aperture.active = !aperture.active;
      if (aperture.active) {
        const obj = {};
        obj[code] = 1;
        this.apertureList.push(obj);
      }
      if (!aperture.active) {
        // tslint:disable-next-line:no-shadowed-variable
        for (const k in this.apertureList) {
          if (this.apertureList[k][code]) {
            delete this.apertureList[k];
            this.apertureList = this.apertureList.filter(item => item);
            aperture.count = 1;
            break;
          }
        }
      }
    }
    this.genAperturesLink();
  }
  public setApertureQuantity = (type, code, step) => {
    // tslint:disable-next-line:forin
    for (const k in this.apertureList) {
      if (this.apertureList[k][code] === 1 && step < 0) {
        return;
      }
      if (this.apertureList[k][code]) {
        this.apertureList[k][code] = this.apertureList[k][code] + step;
        break;
      }
    }
    for (const k in this.apertures) {
      if (this.apertures[k].code === code) {
        this.apertures[k].count = this.apertures[k].count + step;
        break;
      }
    }
    this.genAperturesLink();
  }
}
