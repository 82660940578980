import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-handle',
  templateUrl: './handle.component.html',
  styleUrls: ['./handle.component.scss']
})
export class HandleComponent implements OnInit {

  @Input() modal;

  public appConfig;

  constructor(private calculator: Calculator) { }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public closeModalWithOldConfig = () => this.calculator.closeModalWithOldConfig();
  public getCost = () => (this.calculator.getCost(this.modal.windowId, 'handle'));
  public closeModal = () => this.calculator.closeModal();
  public changeCostValue = (valueId: number) => this.calculator.changeCostValue('handle', valueId.toString());


}
