export class Systems {
  public data: any[] = [
    {
      id: '8',
      name: 'PROVEDAL',
      title: 'PROVEDAL <br> Защита от дождя и ветра',
      description: 'Отличное решение для небольших балконов – раздвижная конструкция экономит место и имеет небольшой вес, ' +
        'но при этом защитит от ветра, пыли и грязи.',
      warm: '0,07 (м <sup>2</sup> x <sup>o</sup>C)/Вт',
      noise: '18 дБ',
      height: '80 мм',
      profile: 'алюминиевый, 64 мм',
      package: 'стекло 4 мм',
      sealing: 'черное каучуковое'
    },
    {
      id: '95',
      name: 'РЕХАУ EXPERIENCE 60',
      title: 'РЕХАУ EXPERIENCE 60 <br>Оптимальное качество',
      description: 'Окна соответствуют всем современным требованиям энергосбережения и безопасности – продукт, сертифицированный по международным стандартам.',
      warm: '0,78 (м <sup>2</sup> x <sup>o</sup>C)/Вт',
      noise: '37 дБ',
      height: '122 мм',
      profile: '4-х камерный, 60 мм',
      package: 'Теплопакет 32 мм',
      sealing: 'каучуковое РАУТЬЮБ'
    },
    {
      id: '96',
      name: 'РЕХАУ EXPERIENCE 70',
      title: 'РЕХАУ EXPERIENCE 70 <br>Лучшие характеристики ',
      description: 'Система адаптирована для российских условий и включает только 100% оригинальные и проверенные элементы – отличное вложение ваших средств.',
      warm: '0,94 (м <sup>2</sup> x <sup>o</sup>C)/Вт',
      noise: '40 дБ',
      height: '110 мм',
      profile: '5-ти камерный, 70 мм',
      package: 'Теплопакет 40 мм',
      sealing: 'каучуковое РАУТЬЮБ'
    },
    {
      id: '73',
      name: 'РЕХАУ BLITZ',
      title: 'РЕХАУ BLITZ <br> Экономное остекление',
      description: 'Идеальный по сочетанию стоимости и качества вариант для остекления новых объектов или реконструкции жилых зданий.',
      warm: '0,57 (м <sup>2</sup> x <sup>o</sup>C)/Вт',
      noise: '37 дБ',
      height: '105 мм',
      profile: '3-х камерный, 60 мм',
      package: '32 мм',
      sealing: 'черное каучуковое'
    },
    {
      id: '78',
      name: 'РЕХАУ GRAZIO',
      title: 'РЕХАУ GRAZIO <br> Хит продаж',
      description: 'Оптимальный выбор для городских квартир: изящные скошенные формы профиля и хорошие теплозащитные свойства.',
      warm: '0,78 (м <sup>2</sup> x <sup>o</sup>C)/Вт',
      noise: '40 дБ',
      height: '112 мм',
      profile: '5-ти камерный, 70 мм',
      package: 'Теплопакет 40 мм',
      sealing: 'черное каучуковое'
    },
    {
      id: '76',
      name: 'РЕХАУ INTELIO 80',
      title: 'РЕХАУ INTELIO 80 <br> Максимум тепла',
      description: 'Отличный выбор для квартир премиум класса: безупречное качество и отличные характеристики тепло- и ' +
        'шумоизоляции отвечают самым строгим требованиям.',
      warm: '0,85 (м <sup>2</sup> x <sup>o</sup>C)/Вт',
      noise: '41 дБ',
      height: '107 мм',
      profile: '6-ти камерный, 80 мм',
      package: 'Теплопакет 40 мм',
      sealing: 'черное каучуковое'
    }

  ];
}
