export class Pik1Apertures {
  public data = [
    {
      name : 'Одностворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4315.png',
      code : '4315',
      active : false,
      count : 1
    },
    {
      name : 'Одностворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4311.png',
      code : '4311',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой малое',
      img : 'assets/apimgs/4313.png',
      code : '4313',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой среднее',
      img : 'assets/apimgs/4314.png',
      code : '4314',
      active : false,
      count : 1
    },
    {
      name : 'Двустворчатое окно с глухой фрамугой большое',
      img : 'assets/apimgs/4312.png',
      code : '4312',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок малый',
      img : 'assets/apimgs/4330.png',
      code : '4330',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок большой',
      img : 'assets/apimgs/4329.png',
      code : '4329',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок с окном до пола',
      img : 'assets/apimgs/4331.png',
      code : '4331',
      active : false,
      count : 1
    }
  ];
  public name = 'Серия домов ПИК-1';
}
