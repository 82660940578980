import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpService} from '../../services/http.service';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pre-order',
  templateUrl: './pre-order.component.html',
  styleUrls: ['./pre-order.component.scss']
})
export class PreOrderComponent implements OnInit {

  @Input() order;
  @Output() onchanged = new EventEmitter<boolean>();

  public form: FormGroup;
  public sended = false;

  constructor(private http: HttpService, private calculator: Calculator, private fb: FormBuilder) {}

  ngOnInit() {
    this.initForm();

    // 5.1.1
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      eCategory: 'calculator',
      eAction: 'openCalcToEmail',
      eLabel: '',
      eNI: false,
      event: 'GAEvent'
    });
  }

  public initForm = () => {
    this.form = this.fb.group({
      userStreet: [this.calculator.street],
      userHouse: [this.calculator.house],
      userFullAddress: [this.calculator.fullAddress],
      href: [window.location.href],
      appartFloor: [this.calculator.appartFloor],
      userName: ['', [Validators.required, Validators.minLength(3)]],
      userEmail: ['', [Validators.required, Validators.email]],
      userPhone: ['', [Validators.required, Validators.minLength(9)]],
      order: [this.order]
    });
  }

  public isControlInvalid = (controlName: string): boolean => {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }

  public checkPreOrderForm = (e) => {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      return;
    }
    this.preOrderExecute();
    return true;
  }

  public preOrderExecute = () => {

    const request = {
      userStreet: this.calculator.street,
      userHouse: this.calculator.house,
      userFullAddress: this.calculator.fullAddress,
      href: window.location.href,
      appartFloor: this.calculator.appartFloor.toString(),
      order: this.order,
      userName: this.form.get('userName').value,
      userPhone: this.form.get('userPhone').value,
      userEmail: this.form.get('userEmail').value,
      siteId: 5
    };

    this.http.preOrderExecute(request).subscribe(res => {
      if (res.status === 1 && res.preOrderId > 0) {
        this.sended = true;

        // 5.1.2
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          eCategory: 'conversion',
          eAction: 'sendCalcToEmail',
          eLabel: '',
          eNI: false,
          event: 'GAEvent'
        });
      }
    });
  }

}
