export class Spb1LgApertures {
  public data = [
    {
      name : 'Двустворчатое окно',
      img : 'assets/apimgs/5807.png',
      code : '5807',
      active : false,
      count : 1
    },
    {
      name : 'Трехстворчатое окно',
      img : 'assets/apimgs/6023.png',
      code : '6023',
      active : false,
      count : 1
    },
    {
      name : 'Балконный блок',
      img : 'assets/apimgs/6522.png',
      code : '6522',
      active : false,
      count : 1
    }
  ];
  public name = 'Серия домов С-Пб-1Лг-507';
}
