import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-floorfinish',
  templateUrl: './floorfinish.component.html',
  styleUrls: ['./floorfinish.component.scss']
})
export class FloorfinishComponent implements OnInit {

  @Input() modal;
  public appConfig;

  constructor(private calculator: Calculator) { }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public closeModal = () => this.calculator.closeModal();
  public closeModalWithOldConfig = () => this.calculator.closeModalWithOldConfig();
  public getCost = () => (this.calculator.getCost(this.modal.windowId, 'floorfinish'));
  public changeCostValue = (valueId: number) => this.calculator.changeCostValue('floorfinish', valueId.toString());

}
