import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  @Input() plan: any;
  @Input() apertures: any;
  @Input() balkons: any;

  constructor(public snt: DomSanitizer) {  }

  ngOnInit() {  }
}
