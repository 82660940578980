import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cost-modal',
  templateUrl: './cost-modal.component.html',
  styleUrls: ['./cost-modal.component.scss']
})
export class CostModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
