import {Component, ElementRef} from '@angular/core';

declare global {
  interface Window { dataLayer: any; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'ng11';
  public loading = true;
  public modalSearch = false;
  public modalMap = false;

  constructor(private el: ElementRef) {
  }

  public openModalSearch = () => {
    this.modalSearch = true;
    var elem = document.querySelector("body");
    elem.classList.add("hidden");
    // 2
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      eCategory: 'calculator',
      eAction: 'clickCalculatorButton',
      eLabel: '',
      eNI: false,
      event: 'GAEvent'
    });
  }

  public closeModalSearch = (counter = false) => {
    this.modalSearch = false;
    var elem = document.querySelector("body");
    elem.classList.remove("hidden");
    // 3.1
    if (counter) {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        eCategory: 'calculator',
        eAction: 'clickCalculateButton',
        eLabel: '',
        eNI: false,
        event: 'GAEvent'
      });
    }
  }

  public openModalMap = () => {
    this.modalMap = true;
  }

  public closeModalMap = () => {
    this.modalMap = false;
  }

  public ota = (object: object): any[] => {
    const keys = Object.keys(object);
    const res = [];
    for (const k of keys) {
      res.push(object[k]);
    }
    return res;
  }
  public sortObject = (object: object): any[] => {
    let res = [];
    // tslint:disable-next-line:forin
    for (const k in object) {
      object[k].code = k;
    }
    res = Object.values(object);
    res.sort((a, b) => {
      if (a.sort > b.sort) {
        return 1;
      }
      if (b.sort > a.sort) {
        return -1;
      }
      return 0;
    });
    return res;
  }

  public scrollToElement = (selector: string) => {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      selector
    );

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth'
    });
  }

  public getTopOffset = (controlEl: HTMLElement): number => {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
  public getPlan = () => {
    return JSON.parse(localStorage.getItem('app.plan'));
  }
}
