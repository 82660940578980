import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-series',
  templateUrl: './add-series.component.html',
  styleUrls: ['./add-series.component.scss']
})
export class AddSeriesComponent implements OnInit {
  @Input() showModal: boolean;
  @Input() seriesId: string;
  @Input() fullAddress: string;

  @Output() changed = new EventEmitter<boolean>();

  private apiUrl = 'https://lk.azbukaokna.ru/API/FORMS/';
  public form: FormGroup;
  public formSended = false;

  constructor( private http: HttpClient, private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();

    // 4.1.1
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      eCategory: 'calculator',
      eAction: 'openApartmentLayout',
      eLabel: '',
      eNI: false,
      event: 'GAEvent'
    });
  }

  public toggleShow = () => {
    this.changed.emit();
  }
  public initForm = () => {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(3)]],
      series: [this.seriesId],
      full_address: [this.fullAddress],
      file: ['', [Validators.required]]
    });
  }
  public isControlInvalid = (controlName: string): boolean => {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }
  public onFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('file').setValue(file);
    }
  }
  public onSubmit = () => {
    const controls = this.form.controls;
    /** Form validator */
    if (this.form.invalid) {
      /** If form is no valid, mark all Controls as touched */
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      /** Exit */
      return;
    }
    /** Form data Handler */
    const formData = new FormData();
    formData.append('file', this.form.get('file').value);
    formData.append('name', this.form.get('name').value);
    formData.append('phone', '7' + this.form.get('phone').value);
    formData.append('series', this.seriesId);
    formData.append('email', this.form.get('email').value);
    formData.append('full_address', this.fullAddress);
    this.http.post<any>(this.apiUrl, formData).subscribe((res) => {
      if (res.STATUS === 'OK') {
        this.formSended = true;

        // 4.1.2
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
          eCategory: 'conversion',
          eAction: 'sendApartmentLayout',
          eLabel: '',
          eNI: false,
          event: 'GAEvent'
        });
      }
    }, (err) => console.log(err));
  }
}
