import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';
import Swiper from 'swiper/swiper-bundle';
import { Router } from '@angular/router';


@Component({
  selector: 'app-detailed-plan-image',
  templateUrl: './detailed-plan-image.component.html',
  styleUrls: ['./detailed-plan-image.component.scss']
})
export class DetailedPlanImageComponent implements OnInit {

  constructor(public calculator: Calculator) { }
  //constructor(private router: Router) { }

  @Input() modal;
  @Input() plan3D_index;

  public planId;
  public appConfig;
  public curentPageLink;
  public seriesId = this.calculator.seriesId;
  public street = this.calculator.street;
  public house = this.calculator.house;
  public detailedImages = this.calculator.detailedImages;
  public activeCountRooms = this.calculator.activeCountRooms;
  public arPlans = this.calculator.arPlans;

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
    this.swiperLoad(this.plan3D_index);
  }

  public closeModal = () => {
    let body = document.querySelector("body");
    body.classList.remove("body-popup-overflow");
    this.calculator.closeModal();
  }
  public ota = (o) => (this.calculator.ota(o));

  public swiperLoad (index) {
    //let seriesID = this.seriesId;
    let plan3DId;
    let room_plans = this.arPlans.room_plans[this.activeCountRooms];
    let $chooseButton = document.querySelector("#chooseButton");
    //let $plan = document.querySelector("#plan");
    //setTimeout(function () {
      new Swiper('.azbukaokna-3Dplans_slider', {
        spaceBetween: 24,
        slidesPerView: 1,
        speed: 600,
        grabCursor: true,
        roundLengths: true,
        initialSlide: index,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          init: function(){
            plan3DId = room_plans[this.realIndex].code;
            $chooseButton.setAttribute('planId', plan3DId);
          },
          slideChange: function(){
            plan3DId = room_plans[this.realIndex].code;
            //let routelink = "/house/raschet-ceny-osteklenya-kvartiry/"+seriesID+"/"+ plan3DId;
            $chooseButton.setAttribute('planId', plan3DId);
          }
        }
      });
   // }, 100);
    this.planId = plan3DId;
  }

  setActive3dPlan() {
    let $chooseButton = document.querySelector("#chooseButton");
    let Idplan = $chooseButton.getAttribute('planId');
    let curentPageLink = new URL (window.location.href);
    let arGet = curentPageLink.search.split('&');
    let link = "/house/raschet-ceny-osteklenya-kvartiry/"+this.seriesId+"/"+ Idplan + arGet[0] + '&' + arGet[1] + "&activeCountRooms=" + this.activeCountRooms;
    window.location.href = link;
    this.closeModal();
  }
}
