import {Component, Input, OnInit} from '@angular/core';
import {CalculatorComponent as Calculator} from '../../pages/calculator/calculator.component';

@Component({
  selector: 'app-loggdecor',
  templateUrl: './loggdecor.component.html',
  styleUrls: ['./loggdecor.component.scss']
})
export class LoggdecorComponent implements OnInit {

  @Input() modal;
  public appConfig;

  constructor(private calculator: Calculator) { }

  ngOnInit() {
    this.appConfig = this.calculator.appConfig;
  }

  public closeModal = () => this.calculator.closeModal();
  public closeModalWithOldConfig = () => this.calculator.closeModalWithOldConfig();
  public getCost = () => (this.calculator.getCost(this.modal.windowId, 'loggdecor'));
  public construction = () => (this.calculator.construction(this.calculator.modal.windowId));
  public changeLoggdecorType = (decorId) => {
    const construction = this.calculator.construction(this.modal.windowId);
    construction.costs.loggdecor.calculate = construction.costs.loggdecor2.calculate = false;
    construction.costs.loggdecor.show = construction.costs.loggdecor2.show = false;
    construction.costs[decorId].calculate = construction.costs[decorId].show = true;
    const types = JSON.parse(JSON.stringify(construction.loggdecorType.values));
    // tslint:disable-next-line:forin
    for (const t in types) {
      if (types[t].id === decorId) {
        types[t].active = true;
        continue;
      }
      types[t].active = false;
    }
    construction.loggdecorType.values = types;
    /** Values */
    const oposite = decorId === 'loggdecor' ? 'loggdecor2' : 'loggdecor';
    const activeVal = decorId === 'loggdecor' ? 4559 : 5719;
    // tslint:disable-next-line:forin
    for (const v in construction.costs[oposite].values) {
      construction.costs[oposite].values[v].active = false;
    }
    // tslint:disable-next-line:forin
    for (const v in construction.costs[decorId].values) {
      const val = construction.costs[decorId].values[v];
      if (val.id === activeVal) {
        val.active = true;
        continue;
      }
      val.active = false;
    }
    /** */
  }
  public changeCostValue = (costId: string, valueId: number) => {
    this.calculator.changeCostValue(costId, valueId.toString());
  }
}
