import { Component, OnInit} from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpService } from '../../services/http.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public showSystems = false;
  public showModalSearch = false;
  public galleryImages: any;
  public galleryOptions: any;
  public form: FormGroup;
  public showModal = false;
  public showThnx = false;
  private formData = new FormData();
  public toggleBlocks: any = {
    recom: {
      open: false
    },
    review: {
      open: false
    },
  };
  public systems: any = [
    {
      id: 'bl',
      name: 'Rehau Blitz',
      active: true
    },
    {
      id: 'gr',
      name: 'Rehau Grazio',
      active: false
    },
    {
      id: 'in',
      name: 'Rehau Intelio',
      active: false
    }
  ];

  constructor(private http: HttpService, private fb: FormBuilder, private app: AppComponent) {}

  ngOnInit() {
    this.app.loading = false;
    this.initForm();
    this.galleryOptions = [
      {
        width: '150px',
        height: '150px',
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnails: false
      }
    ];
    this.galleryImages = [
      {
        small: 'assets/imgs/reviews/photo_01.jpg',
        medium: 'assets/imgs/reviews/photo_01.jpg',
        big: 'assets/imgs/reviews/photo_01.jpg'
      },
      {
        small: 'assets/imgs/reviews/photo_02.jpg',
        medium: 'assets/imgs/reviews/photo_02.jpg',
        big: 'assets/imgs/reviews/photo_02.jpg'
      },
      {
        small: 'assets/imgs/reviews/photo_03.jpg',
        medium: 'assets/imgs/reviews/photo_03.jpg',
        big: 'assets/imgs/reviews/photo_03.jpg'
      },
      {
        small: 'assets/imgs/reviews/photo_04.jpg',
        medium: 'assets/imgs/reviews/photo_04.jpg',
        big: 'assets/imgs/reviews/photo_04.jpg'
      },
      {
        small: 'assets/imgs/reviews/photo_05.jpg',
        medium: 'assets/imgs/reviews/photo_05.jpg',
        big: 'assets/imgs/reviews/photo_05.jpg'
      }
    ];
  }

  public initForm = () => {
    this.form = this.fb.group({
      fio: ['', [Validators.required, Validators.minLength(3)]],
      rating: [''],
      phone: ['', [Validators.required, Validators.minLength(3)]],
      review: ['', [Validators.required, Validators.minLength(3)]],
      number: ['', [Validators.required, Validators.minLength(3)]],
      imgs: ['']
    });
  }
  public toggleBlock = (name: string): void => {
    this.toggleBlocks[name].open = !this.toggleBlocks[name].open;
  }
  public setSystem = (id: string) => {
    // tslint:disable-next-line:forin
    for (const k in this.systems) {
      this.systems[k].active = id === this.systems[k].id;
    }
  }
  public openModal = () => this.showModal = !this.showModal;
  public sendReview = () => {
    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls)
        .forEach(controlName => controls[controlName].markAsTouched());
      return;
    }

    this.app.loading = true;
    setTimeout(() => {
      this.app.loading = false;
    }, 1000);

    this.formData.append('rating', this.form.get('rating').value);
    this.formData.append('fio', this.form.get('fio').value);
    this.formData.append('phone', '7' + this.form.get('phone').value);
    this.formData.append('number', this.form.get('number').value);
    this.formData.append('review', this.form.get('review').value);
    this.http.addReview(this.formData).subscribe(
      (res: any) => {

        this.app.loading = true;
        setTimeout(() => {
          this.app.loading = false;
        });

        if (res.status === 1) {
          this.showModal = false;
          this.showThnx = true;
        }
      },
      (err) => console.log(err)
    );
  }
  public isControlInvalid(controlName: string): boolean {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }
  public addImgs = (event) => {
    const files = event.target.files;
    const imgsPreview = document.getElementById('imgs-preview');
    // tslint:disable-next-line:forin
    for (const i in files) {
      const file = files[i];
      if (!file.type || !file.type.startsWith('image/')) {
        continue;
      }
      // tslint:disable-next-line:radix
      if (parseInt(i) > 4) {
        alert('Количество загружаемых файлов должно быть не больше 5!');
        return;
      }
      this.formData.append('imgs_' + i, file);

      const span = document.createElement('span');
      span.className = 'img-container img-container_' + i;

      const icon = document.createElement('i');
      icon.className = 'img-delete img-delete_' + i;
      span.appendChild(icon);

      const img = document.createElement('img');
      img.src = file;

      span.appendChild(img);

      imgsPreview.appendChild(span);

      const reader = new FileReader();
      reader.onload = ((aImg) => {
        return (e) => {
          aImg.src = e.target.result;
        };
      })(img);

      reader.readAsDataURL(file);
    }
  }
  public openFileInput = () => {
    document.getElementById('imgs').click();
  }

}
