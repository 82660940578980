import {Component, ElementRef, OnInit} from '@angular/core';
import {AppComponent as App} from '../../app.component';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpService } from '../../services/http.service';

interface Modal {
  windowId?: string;
  type?: string;
  values?: any;
  windowData?: any;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})


export class OrderComponent implements OnInit {

  public appOrder: any;
  public appAddress: any;
  public seriesId: string;
  public appConfig = {apiUrl: 'https://lk.azbukaokna.ru'};
  public form: FormGroup;
  public fullAddress: string;
  public fias: string;
  public house: string;
  public showSmsBlock = false;
  public userChecked = false;
  public orderSended = false;
  public tmpSms: string;
  public crmId: string;
  public orderWindow = false;
  public modal: Modal = {};

    constructor(
    private app: App,
    private fb: FormBuilder,
    private http: HttpService,
    public el: ElementRef) {}

  ngOnInit() {
    this.appOrder = JSON.parse(localStorage.getItem('app.order'));
    this.appAddress = JSON.parse(localStorage.getItem('app.address')).data;
    this.fullAddress = JSON.parse(localStorage.getItem('app.address')).value;
    this.getDates();
    this.seriesId = this.appOrder.seriesId;
    this.fias = this.appAddress.fias;
    this.initForm();
    this.app.loading = true;
    setTimeout(() => this.app.loading = false, 1000);
    setTimeout(() => this.app.scrollToElement('.basket-data'), 1000);

    // 5.2.1
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      eCategory: 'calculator',
      eAction: 'openOrderFromCalc',
      eLabel: '',
      eNI: true,
      event: 'GAEvent'
    });
  }

  private initForm = () => {
    this.form = this.fb.group({

      userCity: [this.appAddress.city, [Validators.required]],
      userStreet: [this.appAddress.street, [Validators.required]],
      userHouse: [this.appAddress.house, [Validators.required]],
      fullAddress: [this.fullAddress],
      userBuilding: [this.appAddress.block ? this.appAddress.block_type + ' ' + this.appAddress.block : ''],
      userFloor: [this.appOrder.appartFloor, [Validators.required, Validators.minLength(1)]],
      userApartment: ['', [Validators.required]],
      userGate: ['', [Validators.required]],
      userFullAddress: [this.fullAddress],
      userName: ['', [Validators.required, Validators.minLength(3)]],
      userEmail: ['', [Validators.required, Validators.email]],
      userPhone: ['', [Validators.required, Validators.minLength(9)]],
      order: [this.appOrder],
      userComment: [''],
      orderLifting: [''],
      orderDelivery: [''],
      siteId: [5],
      orderInstall: [''],
      orderPayment: ['Наличными', [Validators.required]],
      userSms: [''],
      userElevator: ['Нет']
    });
  }

  public checkLocalStorageLifeTime = () => {
    const checkTime = new Date().getTime() - 3600000;

    return this.app.getPlan().lifeTimeReset > checkTime ? true : false;
  }

  public orderExecute = () => {
    this.checkSms();

    if (!this.checkLocalStorageLifeTime()){
      this.modal.type = "lifeTimeReset";
      return false;
    }

    const controls = this.form.controls;
    if (this.form.invalid) {
      Object.keys(controls)
        .forEach(controlName => {
          controls[controlName].markAsTouched();
        });

      this.form.markAsTouched();
      this.app.scrollToElement('form .ng-invalid');

      return;
    }
    const request = {
      userCity: this.form.get('userCity').value,
      userStreet: this.form.get('userStreet').value,
      userHouse: this.form.get('userHouse').value,
      userBuilding: this.form.get('userBuilding').value,
      fullAddress: this.form.get('fullAddress').value,
      userFullAddress: this.form.get('fullAddress').value,
      userApartment: this.form.get('userApartment').value,
      userFloor: this.form.get('userFloor').value,
      userGate: this.form.get('userGate').value,
      userElevator: this.form.get('userElevator').value,
      userComment: this.form.get('userComment').value,
      userName: this.form.get('userName').value,
      userEmail: this.form.get('userEmail').value,
      userPhone: this.form.get('userPhone').value,
      orderDelivery: this.form.get('orderDelivery').value,
      orderInstall: this.form.get('orderInstall').value,
      orderPayment: this.form.get('orderPayment').value,
      orderType: 'РЕХАУ Новый',
      order: this.appOrder,
      siteId: 5
    };
    if (this.userChecked) {
      this.app.loading = true;
      this.http.orderExecute(request).subscribe(res => {
        // tslint:disable-next-line:radix
        if (parseInt(res.orderId) > 0) {
          this.orderWindow = true;
          this.orderSended = true;
          this.crmId = res.crmOrderId;
          setTimeout(() => this.app.loading = false, 1000);

          // 5.2.2
          const dataLayer = window.dataLayer || [];
          dataLayer.push({
            eCategory: 'conversion',
            eAction: 'sendOrderFromCalc',
            eLabel: '',
            eNI: false,
            event: 'GAEvent'
          });

          return;
        }
        //console.log(res);
      });
    } else {
      this.showSmsBlock = true;
      this.getSms();
    }
  }

  public checkPhoneInput = () => {
    const input = this.form.controls.userPhone;
    if (input.value.length === 10 && !this.showSmsBlock) {
      this.getSms();
      this.showSmsBlock = true;
    }
  }

  public getSms() {
    const formData = new FormData();
    formData.append('action', 'getSms');
    formData.append('phoneNumber', '+7' + this.form.get('userPhone').value);
    formData.append('siteId', 'rh');
    this.http.getSms(formData).subscribe(res => {
      this.tmpSms = res;
    });
  }

  public checkSms = () => {
    if (this.form.get('userSms').value === this.tmpSms.toString()) {
      this.userChecked = true;
      this.showSmsBlock = false;
      this.form.get('userPhone').disable();
      return;
    }
    alert('Веден неверный код!');
  }

  private getDates = () => {
    this.http.getDate().subscribe(res => {
      if (res) {
        // tslint:disable-next-line:max-line-length
        const delivery = res.delivery.day + '-' + (res.delivery.month.toString().length === 1 ? '0' : '') + res.delivery.month + '-' + res.delivery.year;
        // tslint:disable-next-line:max-line-length
        const install = res.install.day + '-' + (res.install.month.toString().length === 1 ? '0' : '') + res.install.month + '-' + res.install.year;
        this.form.controls.orderDelivery.setValue(delivery);
        this.form.controls.orderInstall.setValue(install);
      } else {
        //console.log(res);
      }
    });
  }

  public changeFalseRadio = (val: string, input: string) => {
      this.form.controls[input].setValue(val);
  }

  public isControlInvalid = (controlName: string): boolean => {
    const control = this.form.controls[controlName];
    const result = control.invalid && control.touched;
    return result;
  }
  public ota = (object: object): any[] => (this.app.ota(object));
  public sortObject = (object: object): any[] => (this.app.sortObject(object));
  public costNameInOrder = (system: any, cost: any) => {
    const name = cost.name;
    if (cost.costId === 'system') {
      return system.construction.name + ' ' + system.name;
    }
    if (cost.costId === 'handle') {
      return 'Ручка ' + cost.name;
    }
    if (cost.costId === 'loggdecor') {
      return 'Отделка лоджии цвет ' + cost.name;
    }
    if (cost.costId === 'floorfinish') {
      return 'Отделка пола цвет ' + cost.name;
    }
    if (cost.costId === 'lamination') {
      return 'Ламинация окна цвет ' + cost.name;
    }
    if (cost.costId === 'sill') {
      return 'Подоконник ' + cost.name;
    }
    if (cost.costId === 'extdoorsill') {
      return 'Порог в отделке лоджии цвет ' + cost.name;
    }
    if (cost.costId === 'extwindsill') {
      return 'Подоконник в отделке лоджии цвет ' + cost.name;
    }
    return name;
  }
  public goBack = (): void => {

    // 5.2.3
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      eCategory: 'calculator',
      eAction: 'backToChangeCalc',
      eLabel: '',
      eNI: false,
      event: 'GAEvent'
    });

    window.history.back();
  }

  public servicesPrice = () => this.appOrder.delivery + +this.appOrder.discharge + +this.appOrder.lifting.value;
  public calculateActiveCosts = (costs) => {
    let k = 0;
    for (const c in costs) {
      if (!(costs[c].price === 0) && costs[c].costId !== 'install') {
        k++;
      }
    }
    return k - 1;
  }

  public toggleDescription = (node) => {
    const item = this.el.nativeElement.querySelector(node);
    if (!item.classList.contains('active')) {
      item.classList.add('active');
      return;
    }
    item.classList.remove('active');
  }


  public closeModal = () => {
    this.modal = {
      windowId: null,
      type: null,
      values: null,
      windowData: null
    };
  }
}
