export class Sakb2Apertures {
    public data = [
        {
            "name" : "Одностворчатое окно с фрамугой",
            "img" : "assets/apimgs/fr_odnostvorch.png",
            "code" : "4251",
            "active" : false,
            "count" : 1
        },
        {
            "name" : "Двустворчатое окно с фрамугой",
            "img" : "assets/apimgs/fr_dvuhstvorch.png",
            "code" : "2979",
            "active" : false,
            "count" : 1
        },
        {
            "name" : "Трехстворчатое окно с фрамугой",
            "img" : "assets/apimgs/fr_trehstvorch.png",
            "code" : "4250",
            "active" : false,
            "count" : 1
        },
        {
            "name" : "Балконная дверь с фрамугой",
            "img" : "assets/apimgs/fr_balcon-odnostvorch.png",
            "code" : "2980",
            "active" : false,
            "count" : 1
        },
        {
            "name" : "Балконный блок с двустворчатым окном",
            "img" : "assets/apimgs/fr_balcon-dvuhstvorch.png",
            "code" : "6346",
            "active" : false,
            "count" : 1
        }
    ];
    public name = 'Серия домов САКБ-2';
}
